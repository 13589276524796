<template>
   <div class="swiper-box">
	   <!-- {{swipeList}} -->
    <van-swipe
        indicator-color="white"
		:autoplay="3000"
        class="banner-swiper-mode3"
        >
        <van-swipe-item v-for="(item,index) in swipeList" :key="index"
		 v-lazy:background-image="item.image"
		 loading="loading"
		 @click="openUrl(item)"
		 style="background-position: center center;background-size: cover;">
        </van-swipe-item>
        </van-swipe>
   </div>
</template>
<script>
	// swipeList
	export default {
		props:{
			swipeList: {
			  type:[Object,Array],
			  required:[],
			},
		},
		methods: {
			openUrl(e){
				// if(e.jump_url && e.jump_url!='#'){
				// 	window.open(e.jump_url)
				// }
				if(e.jump_url && e.jump_url!='#'){
					if(/^http/.test(e.jump_url)){
						window.open(e.jump_url, '_blank');
						return false;
					}
					this.$router.push({name:e.jump_url})
				}
			}
		},
		data() {
			return {
				
			}
		},
		
		
	}
</script>
<style>
    .banner-swiper-mode3{
        border-radius: var(--common-banner-borderRedius);
        height: var(--common-banner-height);
        overflow: hidden;
    }
    .swiper-box{
        padding: .2rem .2rem .2rem;
    }
    .van-swipe__indicator--active{
        width: 6px !important;
    }
    .van-swipe__indicator{
        width: 6px !important;
        height: 6px !important;
        border-radius: 6px;
    }
    .van-swipe__indicators{
        bottom: .1rem !important;
    }
</style>