<template>
	<div>
		<van-dialog v-model="isModel"
		:showConfirmButton="false"
		className="adddConge_c"
		>
		    <div class="adddConge" >
		        <div class="van-dialog__header">{{title}}</div>
		        <div class="pfCXZWFQpExq3MxRvhpj">
					{{content}}
				</div>
				<!-- Para avançar para o próximo nível, é necessário fazer apostas adicionais além do total acumulado de apostas válidas. Por exemplo: se o requisito de apostas válidas para avançar para o VIP1 é de 1000, e o requisito para avançar para o VIP2 é de 2000, então o membro precisa acumular um total de 1000 + 2000 = 3000 apostas válidas para avançar para o VIP2, e assim por diante. -->
		    </div>
		    <img
		        class="closeIcon"
		        @click="$emit('update:isModel',false)"
		        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII=" >
		</van-dialog>
	</div>
</template>

<script>
	import {changeLoginPassword} from "@/apis/modules/login.js";
	import { Toast } from 'vant';
	export default{
		props:{
			isModel:{
				type:Boolean,
				default:()=>{
					return false;
				}
			},
			title:{
				type:String,
				default:()=>{
					return '';
				}
			},
			content:{
				type:String,
				default:()=>{
					return '';
				}
			}
		},
		components:{
		},
		created() {
			
		},
		methods:{
			
		},
		data(){
			return {
				active:2,
			}
		}
	}
</script>
<style scoped>
	.adddConge_c{
	    background-color:transparent;
	    padding: .1rem;
		border: none;
	}
	.adddConge_c .van-dialog__header{
	    font-size: .3rem;
	    color: var(--theme-text-color);
	    padding-top: 0rem;
	}
	
	.adddConge {
	    background-color: var(--theme-main-bg-color);
	    border: thin solid;
	    border-color: var(--theme-color-line);
	    border-radius: .2rem;
	    max-height: calc(100vh - .1rem);
	    max-height: calc(var(--vh, 1vh)*100 - .1rem);
	    overflow: auto;
	    padding: .2rem .3rem .3rem;
	    box-sizing: border-box;
	    margin-bottom: .2rem;
	}
	.pfCXZWFQpExq3MxRvhpj{
	    font-size: .24rem;
	    color: var(--theme-text-color-darken);
	    font-size: .24rem;
	    margin: .3rem 0 .65rem;
	}
	.closeIcon {
	    background: none;
	    border-radius: 50%;
	    height: .6rem;
	    margin:  0 auto;
	    display: block;
	    /* position: absolute; */
	    /* right: 50%; */
	    /* top: auto; */
	    width: .6rem
	}
</style>