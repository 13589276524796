import Vue from 'vue'
import moneyFun from "./moneyFun.js"

const forIn=(JSON)=>{
	for (let ar in JSON){
		Vue.prototype[ar]=JSON[ar]
	}
}
forIn(moneyFun)

Vue.prototype.myLineBreaks = function(text,maxLen=45){
	//return text.replace(/<br>/g, '\n');
	let wordsArray = text.split(" ");
	let newStr = '';
	let lineLen = 0;
	wordsArray.map(function(word) {
		lineLen += word.length;
		if (lineLen >= maxLen) {
			newStr += '\n';
			lineLen = word.length;
		}
		newStr += word + ' ';
		lineLen += 1;
	});
	return newStr;
}
