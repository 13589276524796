<template>
	<div class="login-body Details  promote">
		<van-popup v-model="isRecord" z-index="100" :close-on-click-overlay="false" @close="closeFun" position="bottom">
			<div class="Recharge-box van-dropdown-menu-box">
				<van-nav-bar :title="$t('Login._RegistrodeDepósito')" left-arrow placeholder @click-left="onClickLeft" />
				<div class="header-by-top">
					<van-dropdown-menu @change="qingqList">
					  <van-dropdown-item @change="qingqList" v-model="timValue" :options="timOption" />
					</van-dropdown-menu>
					<div class="Q6pApvofFVQqXQYgxjDI">{{ $t('Login._DepósitoTotal') }}<span>R$ {{reRecords.total}}</span></div>
				</div>
				<div  class="contentList" style="height: ;"> 
				<div v-if="!reRecords.list.length" style="height: 100%;width: 100%;;display: flex;justify-content: center;align-items: center;">
							  <van-empty
								  image-size="2.1rem"
								  style="text-align: center;"
								  class="custom-image"
								  :image="img_none_sj"
								  description="Nenhum jogo disponível"
							  />
				</div>
					<div class="kGiTu6vRZ5IXnXeNLy_P" v-for="(item,index) in reRecords.list" @click="openInfo(item)">
						<div class="tatl0H6xJ7X9PJycDHoS">
							<div class="Ul7BC3PcvbrLSMTYUUMO">
								<div class="suBX7sD8pIzswzbi5L3h">
									<img
										:src="item.pay_icon"
										class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
										style="width: 0.48rem; height: 0.48rem;">
								</div>
								<p class="fE8GIN37TrnC9J1cuHKr">{{item.pay_name}}</p>
							</div>
							<div class="KHloYQUMTbqJnnNMDe1Q">
								<p class="NPw6Bv6NZhiG1SSQRsyU">R$ {{item.money}}</p>
							</div>
						</div>
						<div class="tatl0H6xJ7X9PJycDHoS">
							<div class="Ul7BC3PcvbrLSMTYUUMO">
								<p class="twh21p2Olzp5Bb1krfdv">
									{{item.create_time}}
									<span
										style="ma"
										class="Q3dkOcsKGthjet4ifzKt">{{item.osn}}</span>
									<span
									    @click.stop="copyContent(item.osn)"
										class="PmwtECGo9wfGBMP0uy71 copyIcon kib9l__4gMjQjQVeXxxV">
										<svg-icon className="imageWrap"
											style="color: var(--cms-primary-color);width: .2rem;height: .2rem;margin-left: .2rem;"
											icon-class="comm_icon_copy--svgSprite:all" />
									</span>
								</p>
							</div>
							<!-- 
								
					-->
							<div class="KHloYQUMTbqJnnNMDe1Q">
								<!-- status -->
								<!--  -->
								<span class="TmTlgIIHiQR86nkpcl8M "  v-if="item.status==0">{{ $t('Login._Pagamento') }} {{ $t('Login._Pendente') }}</span>
								<span class="TmTlgIIHiQR86nkpcl8M iSeiyC3bqps4IGGTXatc1"  v-if="item.status==1">{{ $t('Login._Jápago') }}</span>
								<p class="TmTlgIIHiQR86nkpcl8M" v-if="item.status==10 || item.status==2"><span class="Q6NkNwL6l8CDPSdtrO2P" >{{$t('Login._PedidoExpirado') }}</span>
								</p>
							</div>
						</div>
					</div>
								
				</div>
				
			</div>
		</van-popup>
		<DetailsInfo :isShow="isShow" @qingkOngs="qingkOngs" :shoWItemInfo="shoWItemInfo"></DetailsInfo>
	</div>
</template>

<script>
	import DetailsInfo from "@/components/Recharge/Details_info.vue"
	import {rechargeRecords} from "@/apis/modules/login.js"
	// 
	import {tim,timValue} from "@/utils/tim.js"
	export default {
		components: {
			DetailsInfo
		},
		props: {
			isRecord: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				isFocus: false,
				is_Show: false,
				isShow:false,
				activeName: '',
				activeLength: 12,
				reRecords:{},
				shoWItemInfo:{},
				timOption:tim,
				timValue:timValue,
			}
		},
		created() {
			if(this.$store.state.vuex_token_head){
				this.rechargeRecords()
			}
		},
		methods: {
			qingkOngs(){
				this.shoWItemInfo={};
				this.isShow=false;
			},
			openInfo(item){
				this.shoWItemInfo={
					...item,
				};
				this.isShow=true;
			},
			rechargeRecords(){
					rechargeRecords({
						size:1000000,
						time:this.timValue
					}).then(({code,data})=>{
						if(code==0){
							this.reRecords=data;
							console.log(this.reRecords,'reRecords')
						}
					})
			},
			closeFun(){
				this.$emit('isRecordFun',false)
			},
			qingqList(e){
				this.yesterday=e;
				this.rechargeRecords()
			},
			isShmeoteng() {
				this.is_Show = !this.is_Show
			},
			isFun(e) {
				this.isFocus = e;
			},
			onClickLeft() {
				this.isRecord = false
				this.$emit('isRecordFun',false)
			}
		}
	}
</script>
<style>
	.login-body {
		font-weight: 400;
	}

	.login-body .van-popup {
		background-color: transparent;
	}

	.login-body .van-tab--active .imageWrap {
		color: var(--cms-primary-color) !important;
	}

	.promote.Details .van-nav-bar {
		border-bottom: none;
	}

	.promote.Details [class*=van-hairline]::after {
		border-bottom: none !important;
	}
</style>
<style scoped>
	.header-by-top{
		display: flex;
		align-items: center;
		justify-content: space-between;
		    margin: .2rem;
	}
	.header-by-top .Q6pApvofFVQqXQYgxjDI{
		    font-size: .26rem;
			    color: var(--cms-text-color-secondary);
	}
	.header-by-top .Q6pApvofFVQqXQYgxjDI span {
	    color: var(--cms-text-color-primary);
	    margin-left: .08rem;
	}
	.Recharge-box {
		position: relative;
		background-color: var(--cms-background-color-primary);
		height: calc(var(--vh, 1vh)* 100 - .9rem);
		overflow: hidden;
		border-radius: .2rem .2rem 0 0;
	}
	.contentList{
		    height: calc(var(--vh, 1vh)* 100 - 3.9rem);
			overflow: scroll;
			padding: 0 .2rem;
	}
	.iSeiyC3bqps4IGGTXatc1 {
	    color: var(--theme-secondary-color-success) !important;
	}
	.kGiTu6vRZ5IXnXeNLy_P{
		padding: .15rem .2rem !important;
		background-color: var(--cms-background-color-secondary);
	
	}
	.kGiTu6vRZ5IXnXeNLy_P:nth-child(2n) {
	    background-color: transparent;
	}
	.Ul7BC3PcvbrLSMTYUUMO,.tatl0H6xJ7X9PJycDHoS{
		display: flex;
		align-items: center;
	}
	.TmTlgIIHiQR86nkpcl8M{
		    color: var(--theme-secondary-color-finance);
			    font-size: .22rem;
				cursor: pointer;
				display: block;
				text-align: right;
	}
	.tatl0H6xJ7X9PJycDHoS{
		justify-content: space-between;
		    padding: .05rem 0;
	}
	.NPw6Bv6NZhiG1SSQRsyU,.Ul7BC3PcvbrLSMTYUUMO{
		    color: var(--cms-text-color-primary);
	}
	.fE8GIN37TrnC9J1cuHKr{
		font-size: .24rem;
	}
	.NPw6Bv6NZhiG1SSQRsyU {
	    font-size: .26rem;
		font-weight: 700;
	}
	.twh21p2Olzp5Bb1krfdv{
		    color: var(--cms-text-color-secondary);
		    font-size: .2rem;
	}
	.Q6NkNwL6l8CDPSdtrO2P {
		display: flex;
	    color: var(--cms-global-secondary3-color);
		font-size: .2rem;
	}
	.suBX7sD8pIzswzbi5L3h{
		display: flex;
		margin-right: .1rem;
	}
</style>
