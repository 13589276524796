<template>
	<ul class="CNl7QD1FpnStvA1noMH8">
		<li v-for="(item,index) in sidebarsList" class="sidebars-List" :key="index" v-if="item.isShow">
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
				<van-swipe-item @click="openUrl(item_sy)" v-if="item_sy!==true" v-for="(item_sy,index1) in item"
					:key="index1">
					<div style="display: flex;    align-items: flex-end;    flex-direction: column;">
						<img v-lazy="item_sy.image" class="im" alt="">
						<span v-if="false" :data-text="item_sy.title" class="rwwDMEmGMCuTQe2zKMYd undefined">
							<span style="background-image: linear-gradient(rgb(149, 81, 251), rgb(120, 30, 243));">{{item_sy.title}}</span>
						</span>
					</div>
				</van-swipe-item>
			</van-swipe>
			<p class="KXUP_IukrjksI9v7ohfA" @click="item.isShow=false">
				<!-- <img v-lazy="'./../../assets/icons/images/comm_icon_gb.avif'" class="im" alt=""> -->
				<img style="width: .4rem;height: .4rem;" src="./../../assets/icons/images/comm_icon_gb.png"  alt="" />
				<!-- <svg-icon className="svg" icon-class="comm_icon_x--svgSprite:all" /> -->
			</p>
		</li>
		<li @click="homeScrollbar" style="padding-top: .1rem;" v-if="$store.state.Returntotop">
			<div class="LgFw5l227MkKBKpLD64g float-bar">
				<div>
					<div class="GO6MyZdeydW4ZcSgnvkK float-bar__icon-wrap">
						<svg-icon className="svg" icon-class="comm_icon_fhdb--svgSprite:all" />
					</div>
					<div class="oBV4WKCvUQyDmGdMy1nT float-bar__title">Topo</div>
				</div>
			</div>
		</li>
	</ul>
</template>

<script>
	export default {
		props: {
			sidebarsList: {
				type: [Object, Array],
				required: {},
			}
		},
		data() {
			return {
				codeValue: '',
				inputFocus: false,
			};
		},
		mounted(){
		},
		methods: {
			homeScrollbar(){
				this.homeScrollbarDom.scrollTo({
					top: 0,
					behavior: 'smooth' // 平滑滚动效果
				  })
			},
			handleScroll(e){
				console.log(e);
			},
			openUrl(e) {
				if(e.isJumpUrl){
					window.open(e.jump_url, '_blank');
				}else if (e.jump_url && e.jump_url != '#') {
					if(/^http/.test(e.jump_url)){
						window.open(e.jump_url, '_blank');
						return false;
					}
					this.$router.push({name:e.jump_url})
				}
			}
		},
	}
</script>

<style scoped>
	.LgFw5l227MkKBKpLD64g {
	    background-color: var(--cms-background-color-primary);
	    border: .01rem solid var(--cms-divider-color);
	    border-radius: .1rem;
	    -webkit-box-shadow: .02rem 0 .06rem 0 rgba(0,0,0,.1);
	    box-shadow: .02rem 0 .06rem 0 rgba(0,0,0,.1);
	    cursor: pointer;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    min-height: .78rem;
	    padding: .1rem;
	    width: .84rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	 .LgFw5l227MkKBKpLD64g {
	    min-height: 1rem;
	    padding: .14rem .1rem;
		box-sizing: border-box;
	    width: 1.1rem;
		margin-left: .5rem;
	}
	
	.GO6MyZdeydW4ZcSgnvkK {
	    height: .38rem;
	    width: .38rem;
		margin: 0 auto;
	}
	.GO6MyZdeydW4ZcSgnvkK {
	    background-color: var(--cms-primary-color);
	    border-radius: 50%;
	    color: var(--cms-theme-text-color-primary);
	    font-size: .2rem;
		display: flex;
		align-items: center;
		justify-content: center;
	    margin-bottom: .08rem;
	}
	.oBV4WKCvUQyDmGdMy1nT {
	    word-wrap: break-word;
	    -webkit-line-clamp: 2;
		font-size: .2rem;
	    -webkit-box-orient: vertical;
	    color: var(--cms-primary-color);
	    display: -webkit-box;
	    font-size: .18rem;
	    overflow: hidden;
	    text-align: center;
	    text-overflow: ellipsis;
	    vertical-align: middle;
	    width: 100%
	}
	.GO6MyZdeydW4ZcSgnvkK svg{
		width: .2rem;
		height: .2rem;
	}
	.CNl7QD1FpnStvA1noMH8 {
		position: absolute;
		bottom: 1.4rem;
		z-index: 99;
		right: .1rem;
	}

	.CNl7QD1FpnStvA1noMH8 .sidebars-List {
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		margin-top: .2rem;
	}

	.CNl7QD1FpnStvA1noMH8 .sidebars-List .im {
		width: 1.5rem;
		height: 1.5rem;
		
	}

	.KXUP_IukrjksI9v7ohfA {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border-radius: 50%;
		display: -ms-flexbox;
		display: flex;
		height: .4rem;
		justify-content: center;
		position: absolute;
		right: 0;
		z-index: 9999;
		top: 0;
		width: .4rem;
	}

	.KXUP_IukrjksI9v7ohfA .svg {
		color: var(--theme-text-color-lighten);
		height: .08rem;
		width: .08rem;
	}
	.rwwDMEmGMCuTQe2zKMYd {
	    -webkit-text-stroke-width: .01rem;
	    color: transparent;
	    font-size: .18rem;
	    font-weight: 700;
	    height: .28rem;
	    line-height: .28rem;
	    position: relative;
	    -webkit-transform: translate(0);
	    transform: translate(0);
	    white-space: nowrap;
	    width: 100%;
	    z-index: 1
	}
	
	.rwwDMEmGMCuTQe2zKMYd:before {
	    -webkit-text-stroke: .02rem #fff;
	    content: attr(data-text);
	    left: 0;
	    overflow: hidden;
	    position: absolute;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 100%;
	    z-index: -1
	}
	.rwwDMEmGMCuTQe2zKMYd {
	    display: block;
	    font-size: .22rem;
	    height: .36rem;
	    line-height: .36rem;
	    overflow: hidden;
	    text-align: center;
	}
	 .rwwDMEmGMCuTQe2zKMYd>span {
	    display: block;
	    overflow: hidden;
		text-align: center;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 100%
	}
	.rwwDMEmGMCuTQe2zKMYd:before {
	    -webkit-text-stroke: .02rem #fff;
	    content: attr(data-text);
	    left: 0;
	    overflow: hidden;
	    position: absolute;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    width: 100%;
	    z-index: -1
	}
	
 /* .rwwDMEmGMCuTQe2zKMYd:before {
	    -webkit-text-stroke-color: #bc3216
	}
	 */
	 .rwwDMEmGMCuTQe2zKMYd span {
	    -webkit-background-clip: text;
	    background-clip: text
	}
</style>
