import http from './../http.interceptor.js';
 

export function pustRegister(params) {
	return http.post('/v3.login/register', {
		isLOgin:true,
		...params
	})
}

export function login(params) {
	return http.post('/v3.login/login', {
		isLOgin:true,
		...params
	})
}

export function withdrawPassword(params) {
	return http.post('/v3.user/withdrawPassword', params)
}
export function changeLoginPassword(params) {
	return http.post('/v3.user/changeLoginPassword', params)
}


export function payments(params) {
	return http.post('/v3.home/payments', {
		isLOgin:true,
		...params
	})
}



export function recharge(params) {
	return http.post('/v3.pay/recharge', params)
}
export function rechargeRecords(params) {
	return http.post('/v3.pay/rechargeRecords', {
		isLOgin:true,
		...params
	})
}

export function getBank(params) {
	return http.post('/v3.user/getBank', params)
}
export function setBank(params) {
	return http.post('/v3.user/setBank', params)
}

export function withdraw(params) {
	return http.post('/v3.pay/withdraw', params)
}
export function getBetTasks(params) {
	return http.post('/v3.user/getBetTasks', params)
}
export function withdrawRecords(params) {
	return http.post('/v3.pay/withdrawRecords', params)
}







