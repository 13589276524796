<template>
	<div v-if="isNavigation">
		<div class="fSUCsj8Vxa0U3_foZRjt">
			<!-- yuebao -->
			<div class="DrSAta3RAIhChb6OSZTc">
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('yuebao')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.16rem 18.05rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Juros</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCone('notice')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.57rem; height: 0.5rem;background-position: 10.21rem 17.40rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Suporte</div>
		
				</div>
				
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.16rem 16.449rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					
					<div class="R6JnJ5r1Gimo5SZaqJCu">Saques</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$store.commit('isDeposit',true)">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.7rem 14.05rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Depósito</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCone('event')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.16rem 1.16rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Eventos</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('canReceive')">
						<i
							:style="{
									backgroundImage:`url(${_coloImg3Dian5})`
								}"
							style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.7rem 8.1rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
							/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Rebate</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt"  @click="openUrlCone('notice')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.57rem; height: 0.5rem;background-position: 0.02rem .5rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Bônus de Sugestão</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('securityConter')" >
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.7rem 15.04rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Segurança</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('setting')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;    background-position: 8.57em 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Dados</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('report',{
					current:0,
				})">
				<i
					:style="{
							backgroundImage:`url(${_coloImg3Dian5})`
						}"
					style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 4.55em .5rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
					/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Conta</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('report',{
					current:1,
				})">
				<i
					:style="{
							backgroundImage:`url(${_coloImg3Dian5})`
						}"
					style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.16rem 4.04rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
					/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Apostas</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt"@click="openUrlConCuens('report',{
					current:2,
				})">
				<i
					:style="{
							backgroundImage:`url(${_coloImg3Dian5})`
						}"
					style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:.01rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
					/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Relatório</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCone('canReceive')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:5.1rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Pendente</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 7.16rem 15.97rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Gestão Retiradas</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="$emit('ChesngAPopup',true)">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 5.67rem 0.5rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Idioma</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrl(userInfo.set_withdraw_password?'withdraw':'security')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 5.66rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					<div class="R6JnJ5r1Gimo5SZaqJCu">Tarefas de Apostas</div>
		
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlConCuens('rechargeFund')" >
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position: 3.45rem 0.5rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					
					<div class="R6JnJ5r1Gimo5SZaqJCu">Fundo de Previdência</div>
				</div>
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCone('records')">
					<i
						:style="{
								backgroundImage:`url(${_coloImg3Dian5})`
							}"
						style="display: inline-block; position: relative; width: 0.54rem; height: 0.5rem;background-position:4.55rem 17.4rem; background-size: 6.2057692307692305rem 5.4788461538461535rem;"
						/>
					
					<div class="R6JnJ5r1Gimo5SZaqJCu">Histórico</div>
				</div>
				<!-- instagram -->
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCsusy(config.instagram)">
					<img src="https://cdntoos.vr6pg.com/common/upload/1799509986738814978.png" alt="."
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">Instagram</div>
				</div>
				<!-- whatsapp -->
				<div class="nGxwk_oNvWwlxdYhmaMt" @click="openUrlCsusy(config.whatsapp)">
					<img src="https://cdntoos.vr6pg.com/common/upload/1828853325912748034.png" alt="."
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.57rem; height: 0.5rem; object-fit: contain;">
					<div class="R6JnJ5r1Gimo5SZaqJCu">WhatsApp</div>
				</div>
			</div>
		
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	
	import APopup from "@/components/Idioma-popup/index.vue";
	export default {
		name: 'records',
		props:{
			isNavigation:{
				type:Boolean,
				default:()=>{
					return false;
				}
			},
			title:{
				type:String,
				default:()=>{
					return '';
				}
			},
			content:{
				type:String,
				default:()=>{
					return '';
				}
			}
		},
		components:{
			APopup
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo','config','vuex_token_head'])
		},
		data() {
			return{
				isShow:false,
				rememberPassword:window.localStorage.getItem('rememberPassword')
			}
		},
		methods:{
			_remove(){//
				this.isShow=false
			},
			openUrlCone(e){
				this.openUrl(e);
			},
			openUrlConCuens(e,a){
					if(!this.vuex_token_head){
						if( this.rememberPassword && this.rememberPassword.length){
							this.$store.commit('isRecord')
							this.$store.commit('publicFun',['isRecordType',1])
						}else{
							this.$store.commit('isRecord')
							this.$store.commit('publicFun',['isRecordType',0])	
						}
						return false;
					}
					this.openUrl(e,a);
			},
			openUrlCsusy(e){
				if(false && !this.vuex_token_head){
					if( this.rememberPassword && this.rememberPassword.length){
						this.$store.commit('isRecord')
						this.$store.commit('publicFun',['isRecordType',1])
					}else{
						this.$store.commit('isRecord')
						this.$store.commit('publicFun',['isRecordType',0])	
					}
					return false;
				}
				if(e!='#'){
					window.open(e, '_blank');
				}
			}
		}
	}

</script>

<style scoped>
	.fSUCsj8Vxa0U3_foZRjt {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .14rem;
		box-sizing: border-box;
		-webkit-box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		box-shadow: 0 .04rem .12rem 0 rgba(0, 0, 0, .1);
		overflow: auto;
		position: absolute;
		right: 0;
		top: 1.05rem;
		z-index: 100;
		padding: .2rem .1rem .1rem;
		width: 5.4rem
	}

	.DrSAta3RAIhChb6OSZTc {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.nGxwk_oNvWwlxdYhmaMt {
		margin-bottom: .02rem;
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-right: .05rem;
		max-width: 1rem;
		min-width: .9rem;
		position: relative
	}

	.R6JnJ5r1Gimo5SZaqJCu {
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		line-height: 1.2;
		min-height: .6rem;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		color: var(--theme-text-color);
		font-size: .2rem;
		word-break: break-all;
		text-align: center;
	}
</style>