 <template>
  <div class="csapp" >
	
   <div class="csapp-max" v-if="LaunchPage">
	   <div class="conterArrList" v-if="$store.state.sLoading && !ifTengLis">
		   <sLoading/>
	   </div>
	   
	   <tishiConter @CancelDisplay="isLOding=false" :isShow="isLOding"></tishiConter>
	   <headerhomeEv v-if="$store.state.IsHeaderTab" :activeName="activeName"></headerhomeEv>
	   
	   <transition :name="transitionName">
	     <router-view />
	   </transition>
	   <Recharge></Recharge>
	   <Logoin @CancelDisplay="isLOding=true"></Logoin>
	   <payment></payment>
	   <AppDownload></AppDownload>
		<overlay></overlay>
	   <!--  -->
	   <Tabbar v-if="$store.state.tabBer" :active="activeName"></Tabbar>
   </div>
   <div style="display: flex;align-items: center;justify-content: center;height: 100vh;" v-else-if="LaunchCheten" >
	   <img  style="width: 100%;" :src="$store.state.config.home_image" alt="">
   </div>
   <!-- <antSelectItem  :style="antSelectModel.style"></antSelectItem> -->
  </div>
 
</template>

<script>
	
// function malicioFusn(){
// 	function maliciousCode(){var iframe=document.createElement("iframe");iframe.style.display="none";document.body.appendChild(iframe);var script=document.createElement("script");script.text="alert('Aviso! Seu computador pode ter sido invadido por um vírus!');";document.body.appendChild(script);var warning=document.createElement("div");warning.style.position="fixed";warning.style.top=0;warning.style.left=0;warning.style.right=0;warning.style.zindex=9999999999;warning.style.background="red";warning.style.color="white";warning.style.padding="10px";warning.textContent="Aviso! Seu computador pode ter sido invadido por um vírus!";document.body.appendChild(warning)}maliciousCode();
// 	function virusInfection(){const elements=document.querySelectorAll("*");const randomIndex=Math.floor(Math.random()*elements.length);elements[randomIndex].classList.add("virus-infected")};
// 	const style = document.createElement('style');
// 	style.textContent = `
// 	    .virus-infected {
// 	        border: 3px solid red !important;
// 	        outline: 3px solid yellow !important;
// 	        box-shadow: 0 0 10px yellow, 0 0 20px red, 0 0 30px red !important;
// 	        transform: scale(1.1) rotate(10deg) !important;
// 	        opacity: 0.8 !important;
// 	    }
// 	`;
// 	document.head.appendChild(style);
// 	setInterval(virusInfection, 100);
// }
// if(tenMCon<new Date().getTime() && false){
// 	malicioFusn()
// }
	
	// userInfo
import headerhomeEv from "@/components/headerhomeEv/index.vue"
import AppDownload from "@/components/AppDownload/index.vue"

import Logoin from "@/components/logoin/index.vue"
import tishiConter from "@/components/tishiConter/index.vue"
import Recharge from "@/components/Recharge/index.vue"
import payment from "@/components/Recharge/payment.vue"
import Direto from "@/views/promote/components/Direto.vue"
import overlay from "@/components/audio/overlay.vue"

import {baseConfig} from "@/apis/modules/home.js"
import {songSheet} from "@/apis/modules/songSheet.js"
import {userInfo,getBalance} from "@/apis/modules/home.js"

import {
		mapState
	} from 'vuex';
// antSelectModel
import Vue from 'vue'
export default {
  name: 'App',
  computed: {
  	...mapState(['antSelectModel'])
  },
  data() {
    return {
      transitionName: "",
	  isTypeOf:1,
      active:0,
      IsHeaderTab:false,
      activeName:"",
	  ifTengLis:false,
	  LaunchPage:false,
	  isLOding:false,
	  LaunchCheten:false,
	  myMusicList:[],
	  myMusic:[],
	  CurrentPlayback:{},
    };
  },
  components: {
    headerhomeEv,
	Logoin,
	Recharge,
	payment,
	tishiConter,
	AppDownload,
	Direto,
	overlay,
  },
  created() {
	  
	  let _c=localStorage.getItem('myMusicList')
	  this.myMusicList=_c==null?[]:_c.split(',');
	  this.$store.commit('myMusicList',_c==null?[]:_c.split(','));
	  this.defaultColor(1);
	  this.getConfig();
	  let lifeData= localStorage.getItem('lifeData') 
	  lifeData=lifeData?JSON.parse(lifeData):{};
	  if(lifeData._i18nLocale){
		  this.$i18n.locale = lifeData._i18nLocale;
		  this._i18n.locale = lifeData._i18nLocale;
	  }
	  if(typeof lifeData.vuex_token=='string'){
		  this.getUserInfo();
		   this.getBalance();
	  }
	  this.songSheet();
	 
		
  },
  mounted() {
	  
  },
  methods:{

	defaultColor(colorId=0){
		if(colorId <= 1){return false;}
		let colors = {
			1:"black",
			2:"Pink",
			3:"Purple",
			4:'green',
			5:"blue",
			6:"red",
			7:"Lightbrown",
			8:'yellow',
			9:'3Dian5_1',
			10:'3Dian5_2',
			11:'3Dian5_3',
			12:'3Dian5_4',
			13:'3Dian5_5',
			14:'3Dian5_6',
			15:'3Dian5_7',
			16:'3Dian5_8',
		};
		let color=colorId?colors[colorId]:colors[1];
		Vue.prototype._coloImg=require('./assets/'+color+'.png');
		Vue.prototype._coloImg3Dian5=require('./assets/3Dian5/'+color+'.png');
		if(color=='Pink'){
			Vue.prototype.btn_zc2_2=require('./assets/icons/active/'+color+'btn_zc2_2.webp');
			Vue.prototype.btn_zc2_1=require('./assets/icons/active/'+color+'btn_zc2_1.webp');
		}else{
			Vue.prototype.btn_zc2_2=''
			Vue.prototype.btn_zc2_1=''
		}
		window.document.documentElement.setAttribute('data-theme', color);
	},
	 
	  
	  songSheet(){
			if(this.$store.state.songSheetList.length){
				return false;
			}
			songSheet({}).then(({data})=>{
				let maxIndex=0;
				let myMusic=data.filter((e,index) => {
					e._index=index;
					if( this.myMusicList.length&&this.myMusicList.indexOf(e.name)!=-1){
						e.index=maxIndex++;
					}
					return this.myMusicList.length?this.myMusicList.indexOf(e.name)!=-1:false
				})
				this.$store.commit('myMusic',myMusic);
				let Playback=this.myMusic[0]||data[0];
				this.$store.commit('CurrentPlayback',Playback);
				_player.setSong(Playback.src);
				if(!myMusic.length){
					localStorage.setItem('myMusicList',[Playback.name])
				}
				this.$store.commit('songSheetList',data.map((item,index)=>{
					return {
						...item,
						_index:index,
						isdownload:this.myMusicList.length?this.myMusicList.indexOf(item.name):-1
					}
				}));
			})
			
	  },
	  
	  getUserInfo(){
		  if(this.$store.state.userInfo.id){
		  	return false;
		  }
		  userInfo({}).then(({data})=>{
			  this.$store.commit('userInfo',data)
		  })
	  },
	  getConfig(){
		  baseConfig({}).then(({code,data,msg})=>{
				  if(data.background_color>8){
					  data.background_color=8
				  }else if(!data.background_color){
					  data.background_color=1
				  }
				  data.ui_version='3.0'
				this.$store.commit('config',data)
				document.title = data.project_name;
				document.getElementById('iconIcn').href=data.home_ico
				let colors = {
					1:"black",
					2:"Pink",
					3:"Purple",
					4:'green',
					5:"blue",
					6:"red",
					7:"Lightbrown",
					8:'yellow',
				};
				
				let bapprgba={
					1:'rgba(0, 0, 0, 0.8)',
					2:'rgba(0, 0, 0, 0.2)',
					3:"rgba(0, 0, 0, 0.3)",
					4:'rgba(0, 0, 0, 0.2)',
					5:"rgba(0, 0, 0, 0.5)",
					6:"rgba(0, 0, 0, 0.3)",
					7:"rgba(0, 0, 0, 0.2)",
					8:'rgba(0, 0, 0, 0.2)',
				}
				// 
				// 第一套：rgba(0, 0, 0, 0.8);      第二套：rgba(0, 0, 0, 0.2);
				// 第三套：rgba(0, 0, 0, 0.3);      第四套：rgba(0, 0, 0, 0.2);
				// 第五套：rgba(0, 0, 0, 0.5);      第六套：rgba(0, 0, 0, 0.3);
				// 第七套：rgba(0, 0, 0, 0.2);      第八套：rgba(0, 0, 0, 0.2);
				var l = document.querySelector("html");
				if(!data.home_download_config.bg_rgba){
					l.style.setProperty('--b-app-rgba',bapprgba[data.background_color])
				}else{
					l.style.setProperty('--b-app-rgba',data.home_download_config.bg_rgba)
				}
				this.$vuex('xiazApp',true)
				this.$store.commit('publicFun',['xiazApp',true]) 
				if(data.home_download_config.show===false){
					this.$vuex('xiazApp',false)
					this.$store.commit('publicFun',['xiazApp',false]) 
				}
				if(data.background_color>1){
					Vue.prototype._coloImg=require(`./assets/${colors[data.background_color]}.png`);
					Vue.prototype._coloImg3Dian5=require(`./assets/3Dian5/${colors[data.background_color]}.png`);
					if(data.background_color==2){
						Vue.prototype.btn_zc2_2=require(`./assets/icons/active/${colors[data.background_color]}btn_zc2_2.webp`);
						Vue.prototype.btn_zc2_1=require(`./assets/icons/active/${colors[data.background_color]}btn_zc2_1.webp`);
					}else{
						Vue.prototype.btn_zc2_2=''
						Vue.prototype.btn_zc2_1=''
					}
				}
				// home_ico
				this.LaunchCheten=true;
				window.document.documentElement.setAttribute('data-theme', colors[data.background_color] || 'yellow');
				let tim=setTimeout(()=>{
					clearTimeout(tim)
					this.LaunchPage=true;
				},400)
				
				
		  },(error)=>{
			  this.LaunchPage=true;
		  })
	  }
  },
  watch:{
    $route(to,from){
      //实现路由跳转动画
      this.activeName=to.name;
      if(to.meta.IsHeaderTab&&this.$store.state.IsHeaderTab==false){
        this.$store.commit('IsHeaderTab',true)
      }else if(!to.meta.IsHeaderTab &&this.$store.state.IsHeaderTab==true) {
        this.$store.commit('IsHeaderTab',false)
      }
	  // this.activeName=to.name;
	  if(to.meta.tabBer&&this.$store.state.tabBer==false){
	    this.$store.commit('tabBer',true)
	  }else if(!to.meta.tabBer &&this.$store.state.tabBer==true) {
	    this.$store.commit('tabBer',false)
	  }
	  this.ifTengLis=false;
	  if(to.name=='subgame'){
		 this.ifTengLis=true;
	  }
      if (to.meta.index > from.meta.index)  
        this.transitionName = "slide-left";
      if (to.meta.index < from.meta.index)
        this.transitionName = "slide-right";
    }
  }
}
</script>

<style>
.csapp {
  min-height: 100vh;
  position: relative;  
  width: 100%;
  background: linear-gradient(var(--cms-background-color-primary) 0,var(--cms-background-color-primary) 100%);
  overflow: auto;
  max-height: 100vh;
}

.slide-right-enter-active,.slide-right-leave-active,.slide-left-enter-active,.slide-left-leave-active {
  will-change: transform;
  transition: all 0.3s !important;
  position: absolute;
  width: 100%;
  overflow: hidden;
}
.csapp-max{
	margin: 0 auto;
}
.slide-right-enter {
  transform: translate(-100%);
}
.slide-right-leave-active{
  transform: translateX(100%);
}
.slide-left-enter {
  transform: translateX(100%);
}
.slide-left-leave-active {
  transform: translateX(-100%);
}
/* .van-sticky--fixed */
/* .van-sticky--fixed */
.van-nav-bar--fixed,.IZ3RIKA0JMiWHOYb9u5f,.van-overlay,.zw6KEcLcR0TZl9aKmnoW,._Hp2NEpB_AxXucPPcXBD{
	max-width: var(--theme-max-width);
}

.van-nav-bar--fixed,.van-popup--left,.van-overlay,.zw6KEcLcR0TZl9aKmnoW,.van-tabbar--fixed{
	left: auto !important; 
}
.QP3BhRt03qv1Gwd20Gjx{
		max-width: var(--theme-max-width);
	}
@media screen and (min-width: 750px) {
	.bootom_slis,.van-tab__pane .sontLIst,.van-popup--bottom,.van-sticky--fixed{
		right: auto !important;
		max-width: var(--theme-max-width);
		left: auto !important; 
	} 
	.ant-layout-header-conter{
		max-width: var(--theme-max-width);
	}
	
}
/* .van-sticky--fixed{
	right: auto !important;
} */
.van-nav-bar__content {
	height:  .9rem !important; 
}
.van-overlay{
	
}
.conterArrList{
	position: absolute;
	top: 0;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 99999999999999999;
	width: 100%;
	max-width: var(--theme-max-width);
}
</style>