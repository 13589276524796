var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isNavigation)?_c('div',[_c('div',{staticClass:"fSUCsj8Vxa0U3_foZRjt"},[_c('div',{staticClass:"DrSAta3RAIhChb6OSZTc"},[_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('yuebao')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.16rem 18.05rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Juros")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCone('notice')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.57rem","height":"0.5rem","background-position":"10.21rem 17.40rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Suporte")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrl(_vm.userInfo.set_withdraw_password?'withdraw':'security')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.16rem 16.449rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Saques")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.$store.commit('isDeposit',true)}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.7rem 14.05rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Depósito")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCone('event')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.16rem 1.16rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Eventos")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('canReceive')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.7rem 8.1rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
								backgroundImage:`url(${_vm._coloImg3Dian5})`
							})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Rebate")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCone('notice')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.57rem","height":"0.5rem","background-position":"0.02rem .5rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Bônus de Sugestão")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('securityConter')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.7rem 15.04rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Segurança")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('setting')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"8.57em 17.4rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Dados")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('report',{
				current:0,
			})}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"4.55em .5rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
						backgroundImage:`url(${_vm._coloImg3Dian5})`
					})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Conta")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('report',{
				current:1,
			})}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.16rem 4.04rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
						backgroundImage:`url(${_vm._coloImg3Dian5})`
					})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Apostas")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('report',{
				current:2,
			})}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":".01rem 17.4rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
						backgroundImage:`url(${_vm._coloImg3Dian5})`
					})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Relatório")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCone('canReceive')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"5.1rem 17.4rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Pendente")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrl(_vm.userInfo.set_withdraw_password?'withdraw':'security')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"7.16rem 15.97rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Gestão Retiradas")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.$emit('ChesngAPopup',true)}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"5.67rem 0.5rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Idioma")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrl(_vm.userInfo.set_withdraw_password?'withdraw':'security')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"5.66rem 17.4rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Tarefas de Apostas")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlConCuens('rechargeFund')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"3.45rem 0.5rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Fundo de Previdência")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCone('records')}}},[_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"0.54rem","height":"0.5rem","background-position":"4.55rem 17.4rem","background-size":"6.2057692307692305rem 5.4788461538461535rem"},style:({
							backgroundImage:`url(${_vm._coloImg3Dian5})`
						})}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Histórico")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCsusy(_vm.config.instagram)}}},[_c('img',{staticClass:"goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton",staticStyle:{"width":"0.57rem","height":"0.5rem","object-fit":"contain"},attrs:{"src":"https://cdntoos.vr6pg.com/common/upload/1799509986738814978.png","alt":"."}}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("Instagram")])]),_c('div',{staticClass:"nGxwk_oNvWwlxdYhmaMt",on:{"click":function($event){return _vm.openUrlCsusy(_vm.config.whatsapp)}}},[_c('img',{staticClass:"goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton",staticStyle:{"width":"0.57rem","height":"0.5rem","object-fit":"contain"},attrs:{"src":"https://cdntoos.vr6pg.com/common/upload/1828853325912748034.png","alt":"."}}),_c('div',{staticClass:"R6JnJ5r1Gimo5SZaqJCu"},[_vm._v("WhatsApp")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }