export default {
	lang: {
		title: "uView UI",
		intro: "多平台快速开发的UI框架",
	},
	loain: {
		_Inscreverse: "Sign up",
		_AcessoDeMembro: "member login",
	},
	_home: {
		_Popularjogos: "Popular games",
		_Aexibir: "To be displayed",
		_jogosentre: "games between",
		_Carregarmais: "Load more",
		_Eventos: "Events",
		_Missão: "Mission",
		_Rebate: "Rebate",
		_Pendente: "Pending",
		_VIP: "VIP",
		_Convidar: "To invite",
		_FundodePrevidência: "Provident Fund",
		_Slots: "Slots",
		_Pescaria : "Pescaria",
		_Demo: "Demo",
		_Suporteonline: "Online support",
		_CentraldeAjuda: "help Center",
		_BônusdeSugestão: "Suggestion Bonus",
		_Cassino: "Casino",
		_Jogos: "Games",
		_Suporte: "Support",
		_Contatenos: "Contact us",
		_tis:"The game is in maintenance",
		
	},
	toolbarConter:{
		_Inicio:"Inicio",
		_Promoção:"Promoção",
		_Agente:"Agente",
		_Suporte:"Suporte",
		_Perfil:"Perfil",
		_Depósito:'Depósito',
		_Saque:"Saque",
	},
	_VIP: {
		_Lembrete: "Reminder",
		_LembreteConter: "To advance to the next level, additional bets must be placed beyond the accumulated total of qualifying bets. For example: if the valid wagering requirement to advance to VIP1 is 1000, and the valid wagering requirement to advance to VIP2 is 2000, then the member needs to accumulate a total of 1000 + 2000 = 3000 valid wagers to advance to VIP2 , and so on.",
		_NívelAtual: "Current Level",
		_Restantes: "Remaining",
		_Apostemais: "Bet more",
		_ResgatarTudo: "Redeem Everything",
		_Histórico: "Historic",
		_ListadeníveisVIP: "VIP Tier List",
		_InstruçõessobreregrasVIP: "VIP Rules Instructions",
		_Nível: "Level",
		_Apostaparapromoção: "Bet for promotion",
		_BônusDeAumentoDe: "Boost Bonus",
		_Bônus: "Bonus",
		_Ovalortotaldaretiradadiária: "The total daily withdrawal amount",
		_Olimitesuperiordaretiradadiária: "The upper limit of daily withdrawal",
		_BôTaxasdeisençãodiáriacaneta: "BôDaily Pen Exemption Fees",
		_Totaldedepósitos: "Total deposits",
		_FundodePrevidência: "Provident Fund",
		_Depósito: "Deposit",
		_Retira: "Withdraw",
		_Nãosertapado: "Don't be covered",
		_BônusMáximoNãosertapado: "Maximum Bonus",
		_LimiteMáximoNãosertapado: "Maximum limit",
		_acumulaçãototal0: "total accumulation",
		_Horário: "Time",
		_Depósito: "Deposit",
		_Taxadebônus: "Bonus Rate",
		_Taxadebônus: "Bonus Rate",
		_Númerodeapostas: "Number of bets",
		_Novosrequerimentosparaaposta: "New betting requirements",
		_Vocêprecisaapostar: "You need to bet",
	},
	yuebao: {
		_Depositado: "Deposited",
		_Ciclodeassentamento1horas: "Laying cycle\n1 hour",
		_Coleçãocumulativa: "Cumulative collection",
		_MáximodejurosIlimitado: "Maximum interest\nUnlimited",
		_TaxadeJurosAnual100: "Annual Interest Rate 100.00%",
		_Depósito: "Deposit",
		_Transferir: "transfer",
		_Receber: "To receive",
		_Horas: "Hours",
		_Tipos: "Types",
		_Quantia: "Amount",
		_RendaAcumulada0: "Accumulated Income",
		actiVCo: [{
			text: "Today",
			value: 0
		}, {
			text: "Yesterday",
			value: 1
		}, {
			text: "Last 7 Days",
			value: 2
		}, {
			text: "Last 15 Days",
			value: 3
		}, {
			text: "Last 30 Days",
			value: 4
		}],
		actiVCo1: [{
			text: "All",
			value: 0
		}, {
			text: "Deposit",
			value: 1
		}, {
			text: "transfer",
			value: 2
		}, {
			text: "Receive Balance",
			value: 3
		}]
	},
	description: "No Records",

	toolbar: [{
			title: "Starto"
		},
		{
			title: "Promotiono"
		},
		{
			title: "agente"
		},
		{
			title: "Supporte"
		},
		{
			title: "Profilel"
		},
	],
	popup: {
		nav: [{
				title: "Populationr"
			},
			{
				title: "Slots"
			},
			{
				title: "Populationr"
			},
			{
				title: "Demo"
			},
			{
				title: "Recente"
			},
			{
				title: "Favorites"
			},
		],
		_Slots:"Slots",
		_Demo:'Demo',
		_Recente:"Recente",
		_Popular:"Populationr",
		_Favoritos:"Favorites",
		_Apostas: "Bets",
		_Agente: "Agent",
		_Promoção: "Promotion",
		_Eventos: "Events",
		_Histórico: "Historic",
		_FundodePrevidência: "Provident Fund",
		_Pendente: "Pending",
		_Juros: "Fees",
		_VIP: "VIP",
		_Português: "Portuguese",
		_BaixarApp: "Download App",
		_Suporte: "Support",
		_FAQ: "FAQ",
		_Sobre: "About",
		_Aexibir: "To be displayed",
		_jogosEntre: "games between",
		_PopularIogos: "Popular games",
		_Carregarmais: "Load more",
		_BônusDeAumentoDeNível: "Level Up Bonuses",
		_BônusSemanal: "Weekly Bonus",
		_BônusMensal: "Monthly Bonus",
		_PrivilégioVIP: "VIP privilege",
		_Detalhesdoregistro: "Registration details",
		_Regrasdejuros: "Interest rules",
		_Totalderegistros: "Total records",
		_RequisitosDeapostas: "Wagering Requirements",
		_Regras: "Rules",
	},
	share: {
		_LinkdeConvite: "Invitation Link",
		_MeusDados: "My data",
		_TodososDados: "All Data",
		_Desempenho: "Performance",
		_Comissão: "Commission",
		_DadosdoSubordinado: "Subordinate Data",
		_ApostasdosSubordinados: "Subordinate Bets",
		_TaxadeComissão: "Commission fee",
		_CadastrarSubordinados: "Register Subordinates",
	},
	notice: {
		_Suporte: "Support",
		_Notícia: "News",
		_Notificação: "Notification",
		_PainelRolante: "Rolling Panel",
		_BônusdeSugestão: "Suggestion Bonus",
		_TelegramSuporte: "Telegram Support",
		_FacebookSuporte: "Facebook Support",
		_FAQ: "FAQ",
		_PerguntasFreqüentes: "Common questions",
		_Apoioonline: "Online support",
		_Contactaragora: "Contact now",
		_Conversecomoserv: "Chat with professional online customer service to solve your problems",
		_SemMensagem: "No Message",
	},
	withdraw: {
		_Solicitarsaque: "Request withdrawal",
		_TarefasdeApostas: "Betting Tasks",
		_Contapararecebimento: "Account for receipt",
		_RegistrodeSaques: "Withdrawal Record",
		_Paraasegurança: "For the security of your account, enter your withdrawal password",
		_EsqueceuaSenha: "Forgot password?",
		_Próximo: "Next",
		_InserirSenha: "Enter Password",
		_VerificarSenhadeSaque: "Check Withdrawal Password",
		_DetalhesdeRetiradas: "Withdrawal Details",
		_Tipodetransação: "Transaction type",
		_MétododeRetirada: "Withdrawal Method",
		_HoradeCriação: "Creation Time",
		_Horadeexecução: "Execution time",
		_NúmerodoPedido: "Request number",
		_Vocêtambém: "You also need to effectively bet ",
		_VocêtambémRight:"to withdraw money!",
		_Vezes: "Times",
		_Apostaválidanecessária: "Valid bet required",
		_Adicionarponto: "Add point",
		_Completado: "Completed",
		_fonte: "source",
		_Saldo: "Balance",
		_Vocêprecisaapostar: "You need to bet",
		_sacardinheiro: "withdraw money",
		_Bonus: "Bonus",
		_Estataxade: "This handling fee≈R$0.00(estimated arrival R$1,111.00)",
		_Tudo: "All",
		_Vejaasregrasparadetalhes: "See rules for details",
		_Ganharjuros: "Earn interest",
		_Confirmarretirada: "Confirm withdrawal",
		_TotaldeSaques: "Total Withdrawals",
		_EmitindoparaPIX: "Sending to PIX",
		_AdicionarPIX: "Add PIX",
		_Confirmar: "Confirm",
		_Verifique: "Please check your name and card number carefully, otherwise it cannot be credited",
		_Contapararecebimento: "Account for receipt",
		_Detalhesdatarefadeapostas: "Betting task details",
		_Tipodefonte: "Font type",
		_HoradeCriação: "Creation Time",
		_ObterAdicionarpontos: "Get/Add points",
		_Exigirmultiplicadordeaposta: "Require bet multiplier",
		_Apostaválidanecessária: "Valid bet required",
		_Apostajáválida: "Bet already valid",
		_Vocêprecisaapostar: "You need to bet",
		_Statusdatarefa: "Task status",
		_TempodeConclusão: "Completion Time",
		_Depósito: "Deposit",
		_Adicionar: "To add",
	},
	subgame: {
		_Slots: "Slots",

	},
	report: {
		
	},
	promote: {
		_MeuLink: "My Link",
		_MododeAgente: "Agent Mode",
		_RededoAgente: "Agent Network",
		_Nœud: "Infinite level difference (Nœud de jour)",
		_Coletável: "Collectible",
		_Subordinadosdiretos: "Direct reports",
		_CódigodeConvite: "Invitation Code",
		_Receber: "To receive",
		_Histórico: "Historic",
		_CliqueparaSalvar: "Click to Save",
		_Adicionarmembrosdiretos: "Add direct members",
		_PrimeirosDepósitos: "First Deposits",
		_Depósitos: "Deposits",
		_Depósito: "Deposit",
		_Desempenho: "Performance",
		_Comissão: "Commission",
		_VisãoGeraldosDados: "Data Overview",
		_MeuTime: "My team",
		_Desempenho: "Performance",
		_Comissão: "Commission",
		_Membros: "Members",
		_MembrosDiretos: "Direct Members",
		_OutrosMembros: "Other Members",
		_RendimentoTotal: "Total Yield",
		_DDireto: "D. Direct",
		_DdosOutros: "D. of Others",
		_ComissõesTotais: "Total Commissions",
		_MemberID: "MemberID",
		_ApostasVálidas: "Valid Bets",
		_RecargaDireta: "Direct Recharge",
		_Primeirarecargadireta: "First direct recharge",
		_OutroDepósito: "Other Deposit",
		_Outrasrecargasiniciais: "Other initial top-ups",
		_DepósitoTotal: "Total Deposit",
		_Totaldejogadoresfazendoprimeirosdepósitos: "Total players making first deposits",
		_IDdeMembro: "Member ID",
		_ComissãoDireta: "Direct Commission",
		_OutraComissão: "Other Commission",
		_SubordinadosDele: "His Subordinates",
		_Depósito: "Deposit",
		_DatadeCadastro: "Registration date",
		_ApostasVálidas: "Valid Bets",
		_Datadelogin: "Login date",
		_Atual: "Current",
		_Estado: "state",
		_Númerodeinscritos: "Number of subscribers",
		_Apostasválidas: "Valid bets",
		_Apostaválidadireta: "Direct valid bet",
		_VDdiretas: "direct V/D",
		_Depósitos: "Deposits",
		_PrimeirosDepósitos: "First Deposits",
		_Depósito: "Deposit",
		_ApostasVálidas: "Valid Bets",
		_Ontem: "Yesterday",
		_Hoje: "Today",
		_EstáSemana: "This week",
		_ÚltimaSemana: "Last week",
		_EsteMês: "This month",
		_MêsPassado: "Last month",
		_DataLiquidação: "Settlement Date",
		_customerservice: "customer service",
	},
	mine: {
		_RecuperaroSaldo: "Recover the Balance",
		_Conta: "Account",
		_Apostas: "Bets",
		_Relatório: "Report",
		_GestãoRetiradas: "Withdrawal Management",
		_Convidar: "To invite",
		_Dados: "Data",
		_Segurança: "Security",
		_Música: "Music",
		_Idioma: "Language",
		_FAQ: "FAQ",
		_BônusdeSugestão: "Suggestion Bonus",
		_Façaloginnodispositivo: "Log in to the device",
		_Sobre: "About",
		_Sair: "To go out",
		_Suporte: "Support",
		_Mensagens: "Messages",
		_Dados: "Data",
		_Confirmarsaída: "Confirm exit",
		_Cancelar: "Cancel",
		_Desejaterminarsessãodaconta: "Do you want to log out of your account?",
		_Conta: "Account",
		_Lembrete: "Reminder",
		_Saque: "Withdraw",
		_Depósito: "Deposit",
		_Juros: "Fees",
		_FundodePrevidência: "Provident Fund",
		_Apostaparapromoção: "Bet for promotion",
		_Restantes: "Remaining",
		_Valorrestanteparaaposta: "Remaining amount to bet",
		_Apostaparapromoção: "Bet for promotion",
	},
	Login: {
		_Login: "Login",
		_Registro: "Record",
		_Depósito: "Deposit",
		_Saque: "Withdraw",
		_Juros: "Fees",
		_RegistrodeDepósito: "Deposit Record",
		_Depósitoonline: "Online deposit",
		_Expandir: "Expand",
		_Fechar: "To close",
		_MínimoRechare: "Minimum ",
		_MáximoRechare: ", Maximum ",
		_Lembrete: "Reminder：Click 'Buy Balance', select the amount you need, top up to increase your points! !",
		_RecarregueAgora: "Recharge Now",
		_RegistrodeDepósito: "Deposit Record",
		_Abraseuaplicativo: "Open your payment app and scan or copy and paste the QR code below to complete your purchase;",
		_Estecódigo: "This QR code can only be paid for once. If you need to pay again, go back and top up;",
		_Apósopagamento: "After payment is successful, you can return to the game lobby and wait for points to be added!",
		_DepósitoTotal: "Total Deposit",
		_Pagamento: "Payment",
		_Pendente: "Pending",
		_Jápago: "Already paid",
		_PedidoExpirado: "Expired Order",
		_Tempoefetivo: "Effective time",
		_CopiarcódigoQR: "Copy QR code",
		_EndereçodocódigoQR: "QR code address",
		_Statusdopedido: "Order status",
		_RegistrosdeAuditoria: "Audit Records",
		_HoradeCriação: "Creation Time",
		_NúmerodoPedido: "Request number",
		_Númerodopedidodocomerciante: "Merchant order number",
		_Lembre: "Remember your account password",
		_Suporte: "Mobile Number/AccountLogin Support",
		_Suporteaocliente: "Customer support",
		_JogarGrátis: "Play Demo",
		_EsqueceuaSenha: "Forgot password",
		_dengLuPa: "Please enter Mobile/Account Number",
		_Senha: "Password",
		_SuporteContaRegistro: "Supports only CountRegistration",
		_Registrodocódigodeverificação: "Verification code registration",
		_Cadastrodesenha: "Password registration",
		_accAcrt: "Currency determines third-party games and payment methods and cannot be modified.",
		_Tenhomaisde: "I am over 18 years old, I have read and accept",
		_AcordodeUsuário: "User Agreement",
		_Confirme: "Confirm the password again, the same as the password!",
		_DigiteoNúmerodoCelular: "Enter Mobile Number",
		_VerificaçãodeID: "ID Verification",
		_RedefiniçãodeSenha: "Password Reset",
		_Terminar: "finish",
		_ErroNo: "Account format error, 4-16 characters, supports English/Numbers",
		_Seguintes: "Next",
	},
	RecuperaroSaldo: {
		_RecuperaroSaldo: "Recover the Balance",
		_Saldoatual: "Current balance",
		_Umcliquepararecuperar: "One click to recover",
		_yaTeng: "You can only recover the entire multiple of the balance (i.e. without decimal point)",
		_Tudos: "All",
		_Pesquisadeplataforma: "Platform Search",
	},
	report: {
		_Conta: "Account",
		_Apostas: "Bets",
		_Relatório: "Reporto",
		_Valorcumulativodaaposta: "Cumulative bet value",
		_TotaldeApostasVálidas: "Total Valid Bets",
		_TotaldeV: "Total V/D",
		_DepósitoTotal: "Total Deposit",
		_TotaldeSaques: "Total Withdrawals",
		_AcumuladoTotal: "Total Accumulated",
		_Saldo: "Balance",
		_ApostasVálidas: "Valid Bets",
		_GanhosePerdas: "Gains and Losses",
		_NúmerodaNota: "Note Number",
	},
	security: {
		_SenhadeSaque: "Withdrawal Password",
		_Paraase: "For the safety of your funds, you need to set a withdrawal password first!",
		_Definasuasenhadesaque: "Set your withdrawal password",
		_NovaSenhadeSaque: "New Withdrawal Password",
		_Nãomesmosounúmeroscontínuos: "Cannot be 6 same or continuous numbers",
		_Atenção: "Attention: The withdrawal password protects your funds and is extremely important. Keep it secret to avoid any financial loss.",
		_Confirmar: "Confirm",
		_Senhaincorreta: "Incorrect password, enter again!",
		_Confirmenova: "Confirm the new withdrawal password",
	},
	common: {
		// ......
	},
	index: {
		// ......
	}
}
