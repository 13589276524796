var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Idioma-popup"},[_c('van-popup',{on:{"close":_vm.closeFun},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[(_vm.$attrs.shoWItemInfo.osn)?_c('div',{staticClass:"ant-modal-content"},[_c('div',{staticClass:"ant-modal-header"},[_c('div',{staticClass:"ant-modal-title"},[_vm._v("Detalhes do Depósito")]),_c('span',{staticClass:"ant-modal-close-x",on:{"click":_vm.closeFun}},[_c('i',{staticClass:"anticon anticon-close ant-modal-close-icon",attrs:{"aria-label":"icon: close"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"1em","height":"1em","viewBox":"0 0 16 16","data-icon":"close","fill":"currentColor","aria-hidden":"true","focusable":"false"}},[_c('g',{attrs:{"id":"comm_icon_x","transform":"translate(-1209.5 -160.5)"}},[_c('path',{attrs:{"id":"Line_14","data-name":"Line 14","d":"M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z","transform":"translate(1210.5 161.5)"}}),_c('path',{attrs:{"id":"Line_15","data-name":"Line 15","d":"M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z","transform":"translate(1210.5 161.5)"}})])])])])]),_c('div',{staticClass:"ant-modal-body"},[_c('div',{staticClass:"mwRRRArq5gAZYhhvbvhg"},[_c('div',{staticClass:"AwOlFgTYfgvrUiDuREQ3 iSeiyC3bqps4IGGTXatc",class:{
						MCxdxRhxa0voqrCEptAa1:_vm.$attrs.shoWItemInfo.status==1,
						iSeiyC3bqps4IGGTXatc1:_vm.$attrs.shoWItemInfo.status==2,
						TmTlgIIHiQR86nkpcl8M1:_vm.$attrs.shoWItemInfo.status==0,
					}},[(_vm.$attrs.shoWItemInfo.status==1)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-1.3rem -13.1057rem","background-size":"22.8171rem 22.2671rem"},style:({
									'background-image':`url(${_vm._coloImg})`
								}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e(),(_vm.$attrs.shoWItemInfo.status==0)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-3.6rem -13.057rem","background-size":"22.8171rem 22.2671rem"},style:({
											'background-image':`url(${_vm._coloImg})`
										}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e(),(_vm.$attrs.shoWItemInfo.status==2)?_c('i',{staticStyle:{"display":"inline-block","position":"relative","width":"1.1rem","height":"1.1rem","background-position":"-4.7rem -13.1057rem","background-size":"22.8171rem 22.2671rem"},style:({
											'background-image':`url(${_vm._coloImg})`
										}),attrs:{"data-id":"sprite_main_comm_icon_pay_1","aria-hidden":"true","focusable":"false"}}):_vm._e()]),(_vm.$attrs.shoWItemInfo.status==0)?_c('p',{staticClass:"W81PYwak4A0iuAdse7m7 TmTlgIIHiQR86nkpcl8M"},[_vm._v(_vm._s(_vm.$t('Login._Pagamento'))+" "+_vm._s(_vm.$t('Login._Pendente')))]):_vm._e(),(_vm.$attrs.shoWItemInfo.status==1)?_c('p',{staticClass:"W81PYwak4A0iuAdse7m7 MCxdxRhxa0voqrCEptAa"},[_vm._v(_vm._s(_vm.$t('Login._Jápago')))]):_vm._e(),(_vm.$attrs.shoWItemInfo.status==2)?_c('p',{staticClass:"W81PYwak4A0iuAdse7m7 Q6NkNwL6l8CDPSdtrO2P"},[_vm._v(_vm._s(_vm.$t('Login._PedidoExpirado')))]):_vm._e(),_c('p',{staticClass:"ipChavXPGtlb9XQlgfgp"},[_c('span',[_vm._v("R$ "+_vm._s(_vm.$attrs.shoWItemInfo.money))])])]),_c('ul',{staticClass:"Tx8SM0ickmaT3XlvpBa3"},[_c('li',[_c('span',[_vm._v("Tipo de transação")]),_c('span',[_vm._v("Depósito")])]),_c('li',[_c('span',{},[_vm._v("Método de Depósito")]),_c('span',{},[_vm._v(_vm._s(_vm.$attrs.shoWItemInfo.pay_name))])]),_c('li',[_c('span',{},[_vm._v("Canal de recarga")]),_c('span',{},[_vm._v(_vm._s(_vm.$attrs.shoWItemInfo.pay_name))])]),_c('li',[_c('span',[_vm._v("Hora de Criação")]),_c('span',[_vm._v(_vm._s(_vm.$attrs.shoWItemInfo.time))])]),_c('li',{on:{"click":function($event){$event.stopPropagation();return _vm.copyContent(_vm.$attrs.shoWItemInfo.osn)}}},[_c('span',[_vm._v("Número do Pedido")]),_c('span',{staticClass:"s5Ow2TGiE3H2Yw1AxDNi"},[_vm._v(_vm._s(_vm.$attrs.shoWItemInfo.osn)),_c('span',{staticClass:"PmwtECGo9wfGBMP0uy71 copyIcon glZzWCWRIY240qdjsGDY"},[_c('svg-icon',{staticStyle:{"color":"var(--theme-filter-active-color)","width":".2rem","height":".2rem","margin-left":".2rem"},attrs:{"className":"imageWrap","icon-class":"comm_icon_copy--svgSprite:all"}})],1)])])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }