  	import { Toast } from 'vant';
  async function copyContent (content) {
    let copyResult = true
    const text = content || '~';
    if (!!window.navigator.clipboard) {
      await window.navigator.clipboard.writeText(text).then((res) => {
      }).catch((err) => {
        copyResult =  copyContent2(text)
      })
    } else {
      copyResult =  copyContent2(text)
    }
	Toast.success({
		message:this.myLineBreaks('Copiado com sucesso'),
		duration:1000
	});
  	return copyResult;
  }
  function copyContent2 (text) {
      let copyResult = true
      let inputDom = document.createElement('textarea');
      inputDom.setAttribute('readonly', 'readonly');
      inputDom.value = text;
      document.body.appendChild(inputDom);
      inputDom.select();
      const result = document.execCommand('copy')
      if (result) {
		Toast.success({
			message:'Copiado com sucesso',
			duration:1000
		});
      } else {
        console.log('复制失败');
        copyResult = false
      }
      document.body.removeChild(inputDom);
      return copyResult;
  }
  function removeThousandSeparatorAndDecimalPoint(str) {
      return str.replace(/,|\./g, '');
  }

export {
	removeThousandSeparatorAndDecimalPoint,
	copyContent
}