import {userInfo,getBalance} from "@/apis/modules/home.js"
import store from "@/store"
let $uStore = [];
try {
	$uStore = store.state;
} catch (e) {
}



const getBalanceFun=()=>{//
	  getBalance({
		  withdraw:1
	  }).then(({data})=>{
		  store.commit('publicFun',['moneyInfo',data])
	  })
}

export default{
	getBalance:getBalanceFun,
	btn_zc2_2:require('./../assets/icons/active/btn_zc2_2.webp'),
	btn_zc2_1:require('./../assets/icons/active/btn_zc2_1.webp'),
	_vuex_token:()=>{
		return $uStore.vuex_token || false;
	},
	animate:(e)=>{
		let target=e.target;
		let tagName=target.tagName.toLowerCase()
		if(tagName=='svg'){
			getBalanceFun();
			target.classList.add('animate__manimated');
			let time=setTimeout(()=>{
				clearTimeout(time)
				target.classList.remove('animate__manimated')
			},300)
		}
	},
}