const tim = [{
		text: "Hoje",
		value: 'today'
	},
	{
		text: "Ontem",
		value: 'yesterday'
	},
	{
		text: "Últimos 7 Dias",
		value: "7day"
	},
	{
		text: "Últimos 15 Dias",
		value: '15day'
	},
	{
		text: "Últimos 30 Dias",
		value: '30day'
	},
	{
		text: "Tudo",
		value: 'all',
		is: false
	},
]
const timValue = 'today';


const tim_q = [{
		text: "Hoje",
		value: '0'
	},
	{
		text: "Aguardando apostas",
		value: '1'
	},
	{
		text: "Em andamento",
		value: "2"
	},
	{
		text: "Completado",
		value: '3'
	},
	{
		text: "Abandonado",
		value: '4'
	},
]

let domFunCtina = [{
	text: "Tudo",
	value: "all"
}, {
	text: "Hoje",
	value: "today"
}, {
	text: "Ontem",
	value: "yesterday"
}, {
	text: "Está Semana",
	value: "week"
}, {
	text: "Última Semana",
	value: "last_week"
}, {
	text: "Este Mês",
	value: "month"
}, {
	text: "Últimos 2 meses",
	value: "2month"
}, {
	text: "Últimos 3 meses",
	value: "3month"
}]
let conteNameValue = 'today';

// const ContaType=[	
// 	{text:"Todos os Tipos",value:"0"},
// 	{text:"Troca de Fundos",value:"0"},
// 	{text:"Recarga de Membro",value:"0"},
// 	{text:"Saque de Membro",value:"0"},
// 	{text:"Liquidação do Comerciante",value:"0"},
// 	{text:"Correção de Fundo",value:"0"},
// 	{text:"Eventos",value:"0"},
// 	{text:"Rebate",value:"0"},
// 	{text:"Reembolso",value:"0"},
// 	{text:"Juros",value:"0"},
// 	{text:"Missão",value:"0"},
// 	{text:"Recompensa VIP",value:"0"},
// 	{text:"Oferta de Depósito",value:"0"},
// 	{text:"Clube",value:"0"},
// 	{text:"Auditoria em Segundo Plano",value:"0"},
// ]
const ContaType = [{
		text: "Todos os Status",
		value: "0"
	},
	{
		text: "Não Liquidado",
		value: "1"
	},
	{
		text: "Liquidado",
		value: "2"
	},
	{
		text: "Pedido cancelado",
		value: "3"
	},
]

const ContaTypeValue = '0';
// [
// 	{text:"Fundo de\nPrevidência",value:"0"}
// ]
// [	


// ]
const game_type = [{
		text: "Todos os Tipos",
		value: "0"
	},
	{
		text: "Slots",
		value: "1"
	},
	{
		text: "Pescaria",
		value: "2"
	},
]
const game_typeValue = '0'

const Conta_Type = [
	// {
	// 	text: "Todos os Tipos",
	// 	value: "1"
	// },
	// {
	// 	text: "Entrada/saída de transferência de jogo",
	// 	value: "2"
	// },
	// {
	// 	text: "Retirada",
	// 	value: "3"
	// },
	// {
	// 	text: "bónus",
	// 	value: "8"
	// },
	{text:"Todos os Tipos",value:"1"},{text:"Troca de Fundos",value:"2"},{text:"Recarga de Membro",value:"3"},{text:"Saque de Membro",value:"4"},{text:"Liquidação do Comerciante",value:"5"},{text:"Correção de Fundo",value:"6"},
	// {text:"Eventos",value:"7"},
	// {text:"Rebate",value:"8"},{text:"Reembolso",value:"9"},{text:"Juros",value:"10"},{text:"Missão",value:"11"},
	// {text:"Recompensa VIP",value:"12"},{text:"Oferta de Depósito",value:"13"},{text:"Clube",value:"14"},{text:"Auditoria em Segundo Plano",value:"15"},{text:"Fundo de\nPrevidência",value:"16"},
]

const Conta_TypeValue = '1';
// 0全部（默认），1游戏转入/转出，2充值，3提现，8奖金
// [
// 	{text:"Todas as Plataformas",value:"0"},
// ]
// [
// 	{text:"Todos os Jogos",value:"0"}
// ]
const timValue_q = '0';


const records= [{
		text: "Hoje",
		value: "0"
	}, {
		text: "Ontem",
		value: "1"
	}, {
		text: "Últimos 7 Dias",
		value: "2"
	},
	{
		text: "Últimos 15 Dias",
		value: "3"
	}, {
		text: "Últimos 30 Dias",
		value: "4"
	}, {
		text: "Quase 60 dias",
		value: "5"
	}
]
const recordsTudo=[
	{text:"Tudo",value:"0"},{text:"Resgatado",value:"1"},{text:"Distribuído",value:"2"}
]
export {
	tim,
	timValue,
	timValue_q,
	tim_q,
	ContaType,
	ContaTypeValue,
	game_type,
	game_typeValue,
	Conta_Type,
	Conta_TypeValue,
	domFunCtina,
	records,
	recordsTudo,
	conteNameValue,
}
