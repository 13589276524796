function isPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
    var flag = true;
    for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
}


(function(c, d) {
	// if(isPC()){return false;}
    var e = document.documentElement || document.body,
      a = "orientationchange" in window ? "orientationchange" : "resize",
      b = function() {
        var f = e.clientWidth;
		 var l = document.querySelector("html");
		l.style.setProperty('--vh',(e.scrollHeight/100)+"px")
		if(f >= 750){
				  var l = document.querySelector("html");
				  var i_c = window.innerWidth,
				  n_c = window.innerHeight,
				  r_c = 750 / 1334,
				  c_c = i_c / n_c > r_c ? r_c * n_c : i_c;
				  l.style.setProperty('--theme-max-width', c_c+"px")
				  e.style.fontSize=''
			return false;
		}
        e.style.fontSize = f >= 750 ? "100px" : 100 * (f / 750) + "px";
      };
    b();
    c.addEventListener(a, b, false);
  })(window);
  
 