import http from './../http.interceptor.js';
 

export function shareIndex(params) {
	return http.post('/v3.share/index', params)
}

export function shareMyData(params) {
	return http.post('/v3.share/myData', params)
}
export function shareBonusLog(params) {
	return http.post('/v3.share/bonusLog', params)
}

export function shareInvite(params) {
	return http.post('/v3.share/invite', params)
}


export function shareAllData(params) {
	return http.post('/v3.share/allData', params)
}

export function sharePerformance(params) {
	return http.post('/v3.share/performance', params)
}
export function sharePerformanceDetail(params) {
	return http.post('/v3.share/performanceDetail', params)
}

export function shareSubordinateStats(params) {
	return http.post('/v3.share/subordinateStats', params)
}
export function shareSubordinateWagers(params) {
	return http.post('/v3.share/subordinateWagers', params)
}

export function shareSubordinateBetLog(params) {
	return http.post('/v3.share/subordinateBetLog', params)
}
export function getBetLog(params) {
	return http.post('/v3.user/getBetLog', params)
}

export function getBetReport(params) {
	return http.post('/v3.user/getBetReport', params)
}


export function getBalanceLog(params) {
	return http.post('/v3.user/getBalanceLog', params)
}



export function treasureChest(params) {
	return http.post('/v3.activity/treasureChest', params)
}






