<template>
    <div class="game_item" @click="playGame">
        <div class="game_img" :class="{
			mengBane:item.is_maintenance,
		}">
			<div class="fFW_uHsQoM95EC6sknGg"></div>
			<img class="is_maintenance" v-if="item.is_maintenance" src="./../../assets/icons/images/img_whz_style_1.webp" alt="">
            <img class="gy98qRcKCBtFIJuUTF8N" v-if="item.favorable"  src="./../../assets/icons/images/img_game_tj_1.webp" alt="">
			<div class="" v-if="item.type=='game'"  @click.stop="favorite">
				<img class="rQ13hS2C2xOiatmUjuvy" v-if="item.is_favorite==0 || is_favorite==0"  src="./../../assets/icons/images/btn_sc_off_2.webp" alt="">
				<div class="rQ13hS2C2xOiatmUjuvy _fconte" v-if="item.is_favorite==1 || is_favorite==1"  >
					<img class="rQ13hS2C2xOiatmUjuvy_acction"  src="./../../assets/icons/images/h-ac-star.png" alt="">
				</div>
			</div>
			<div  style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
				<svg-icon  style="width: 100%;height: 100%;color: var(--cms-divider-color);" icon-class="img_mryx_card--svgSprite:all"  />
			</div>
			<lazy-component loading="loading">
			  <img class="conte_img" v-lazy='item.image'   alt="">
			</lazy-component>
            <div class="vyJOQO76ijmamNiW1_eF" v-if="item.type!='platform'">{{item.name}}</div>
        </div>
    </div>
</template>

<script>
	// favoriteGame
	import {favoriteGame} from "@/apis/modules/home.js"
	import { Toast } from 'vant';
	import { mapState } from 'vuex';
	export default {
		name: 'GameItem',
		props: {
			item: {
				type: Object,
				default: {}
			},
			type: {
				type: String,
				default:'subgame'
			},
			category: {
				type: String,
				default:'slot'
			},
			
		},
		data() {
			return {
				is_favorite:false,
				rememberPassword:window.localStorage.getItem('rememberPassword')
			}
		},
		computed: {
			...mapState(['vuex_token_head'])
		},
		methods: {
			checkLogin(){
				if(!this.vuex_token_head){
					if( this.rememberPassword && this.rememberPassword.length){
						this.$store.commit('isRecord')
						this.$store.commit('publicFun',['isRecordType',1])
					}else{
						this.$store.commit('isRecord')
						this.$store.commit('publicFun',['isRecordType',0])	
					}
					return false;
				}
				return true;
			},
			favorite(){
				if(!this.checkLogin())return false;
				favoriteGame({
					game_id:this.item.id,
					is_favorite:this.item.is_favorite==0?1:0,
				}).then(({code})=>{
					this.item.is_favorite=this.item.is_favorite==0?1:0;
					this.is_favorite=this.item.is_favorite
					// this.is_favorite=
				})
			},
			playGame(id) {
				if(this.item.is_maintenance){
					Toast.fail({
						message:this.myLineBreaks(this.$t('_home._tis')),
						duration:1000
					});
					return false;
				}
				if(this.item.type !='platform' && !this.checkLogin())return false;
				// platform平台（点击跳游戏列表），
				// game游戏（点击后跳到玩游戏）
				if(this.item.type=='platform'){
					// webview
					// 
					if(this.type=='home'){
						this.$router.push({name:'subgame',query:{
							id:this.item.id,
							code:this.item.code,
							category:this.item.category||'slot'
						}})
					}
					return false;
					
				}else {
					this.$router.push({name:'webview',query:{
						id:this.item.id,
						category:this.item.category
						// =demo
					}})
				}
				
				// this.$Router.push({
				// 	path: '/pages/webview/webview?ganme_id=' + id
				// })
			}
		}
	}
</script>

<style  scoped>
	.game_item{
        width: 33.33%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }

	
	.mengBane .fFW_uHsQoM95EC6sknGg {
	    background-color: rgba(0,0,0,.5);
	    border-radius: 0;
	    bottom: 0;
	    cursor: not-allowed;
	    left: 0;
	    opacity: 1;
	    position: absolute;
	    right: 0;
	    text-align: center;
	    top: 0;
	    -webkit-transition: opacity .6s;
	    transition: opacity .6s;
	    z-index: 9
	}
   .game_item {
        --card-benchmark: 2.1rem;
    --advertisement-box-width: var(--card-benchmark);
    --advertisement-box-height: calc(var(--card-benchmark)*1.33);
    --advertisement-box-border-width: 0;
    --advertisement-box-border-color: var(--theme-game-lobby-border);
    --advertisement-box-border-radius: 0.2rem;
    --game-platform-name-height: calc(var(--card-benchmark)*0.2);
    --game-platform-name-padding: 0 calc(var(--card-benchmark)*0.05) calc(var(--card-benchmark)*0.05);
    --game-platform-name-font-size: calc(var(--card-benchmark)*0.113);
    --label-game-status-width: calc(var(--card-benchmark)*0.2);
    --label-game-status-height: calc(var(--card-benchmark)*0.15);
    --favorited-icon-top: calc(var(--card-benchmark)*0.033);
    --favorited-icon-right: calc(var(--card-benchmark)*0.033);
    --favorited-icon-bottom: initial;
    --favorited-icon-left: initial;
    --favorited-icon-side: calc(var(--card-benchmark)*0.206);
    --click-arean-expand-size: -0.06rem;
   }
   .game_title{
	   border-radius: 0;
	   text-align: center;
	   width: 100%;
   }
    .game_img{
        --card-benchmark: 2.1rem;
		background-color: var(--cms-background-color-secondary);
        align-items: center;
        border-color: var(--advertisement-box-border-color);
        border-style: solid;
        border-width: var(--advertisement-box-border-width);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        border-radius: var(--advertisement-box-border-radius);
        flex-direction: column;
        height: var(--advertisement-box-height);
        justify-content: flex-end;
        position: relative;
        width: var(--advertisement-box-width);
        overflow: hidden;
        margin:var(--cardColSpacing) var(--cardColSpacing) 0.3rem var(--cardColSpacing);
		
    }
    .conte_img{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
		
    }
	.is_maintenance{
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 9;
	}
    .gy98qRcKCBtFIJuUTF8N {
    height: var(--label-game-status-height);
    left: 0;
    position: absolute;
	z-index: 8;
    top: 0;
    width: var(--label-game-status-width);
}.rQ13hS2C2xOiatmUjuvy {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    bottom: var(--favorited-icon-bottom);
    cursor: pointer;
	border-radius: 50%;
	background-color: rgba(255,255,255,.3);
    display: -ms-flexbox;
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    left: var(--favorited-icon-left);
    line-height: 0;
    position: absolute;
    right: var(--favorited-icon-right);
    top: var(--favorited-icon-top);
    width: fit-content;
    z-index: 9;
    height: var(--favorited-icon-side);
    width: var(--favorited-icon-side);
}
._fconte{
	padding: .08rem;
	box-sizing: border-box;
}
._fconte .rQ13hS2C2xOiatmUjuvy_acction{
	width: 100%;
	height: auto;
	display: block;
}
/* .rQ13hS2C2xOiatmUjuvy_acction{
	-ms-flex-align: center;
	-ms-flex-pack: center;
	align-items: center;
	bottom: var(--favorited-icon-bottom);
	cursor: pointer;
	display: -ms-flexbox;
	display: flex;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	justify-content: center;
	left: var(--favorited-icon-left);
	line-height: 0;
	position: absolute;
	right: var(--favorited-icon-right);
	top: var(--favorited-icon-top);
	width: fit-content;
	z-index: 9;
	height: var(--favorited-icon-side);
	width: var(--favorited-icon-side);
} */
    .vyJOQO76ijmamNiW1_eF{
        line-height: 2.694;
        webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        display: -webkit-box;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-shadow: 0 .02rem .04rem rgba(0, 0, 0, .3);
        vertical-align: middle;
        -ms-flex-pack: center;
        word-wrap: break-word;
        color: var(--theme-text-color-task-btn);
        display: -ms-flexbox;
        display: flex;
        font-size: var(--game-platform-name-font-size);
        height: var(--game-platform-name-height);
        justify-content: center;
        margin-bottom: var(--game-platform-name-margin-bottom);
        padding: var(--game-platform-name-padding);
        width: 100%;
        position: relative;
		position: absolute;
		bottom: 0;
        z-index: 9;
    }
</style>
