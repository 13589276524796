<template>
    <div class="GI7HW_KYGFUGWvsqzBRx M2jH4FHP5DfiUNlWcYJt QY3M1Ti7e9K3q5YGzMaJ  ccXLMbc69hoUikM81AID" @click="playGame">
		<div  style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
			<svg-icon  style="width: 100%;height: 100%;color: var(--theme-game-card-ddt_icon);" icon-class="img_mryx_card--svgSprite:all"  />
		</div>
       <lazy-component loading="loading">
         <img class="conte_img" v-lazy='item.image'   alt="">
       </lazy-component>
		<div class="game-name T0_ZZEQjLqf2QuL3C1DZ O8r1pxpgaW1Wd1QoPbLO" >{{item.name}}</div>
    </div>
</template>

<script>
	// favoriteGame
	import {favoriteGame} from "@/apis/modules/home.js"
	import { Toast } from 'vant';
	export default {
		name: 'GameItem',
		props: {
			item: {
				type: Object,
				default: {}
			},
			type: {
				type: String,
				default:'subgame'
			},
			category: {
				type: String,
				default:'slot'
			},
			
		},
		data() {
			return {
				is_favorite:false,
			}
		},
		methods: {
			favorite(){
				favoriteGame({
					game_id:this.item.id,
					is_favorite:this.item.is_favorite==0?1:0,
				}).then(({code})=>{
					this.item.is_favorite=this.item.is_favorite==0?1:0;
				})
			},
			playGame(id) {
				if(this.item.is_maintenance){
					Toast.fail({
						message:this.myLineBreaks(this.$t('_home._tis')),
						duration:1000
					});
					
					// success
					// duration
					// Toast('O jogo está em manutenção');
					return false;
				}
				// platform平台（点击跳游戏列表），
				// game游戏（点击后跳到玩游戏）
				if(this.item.type=='platform'){
					// webview
					// 
					if(this.type=='home'){
						this.$router.push({name:'subgame',query:{
							id:this.item.id,
							code:this.item.code,
							category:this.item.category||'slot'
						}})
					}
					return false;
					
				}else {
					this.$router.push({name:'webview',query:{
						id:this.item.id,
						category:this.item.category
						// =demo
					}})
				}
				
				// this.$Router.push({
				// 	path: '/pages/webview/webview?ganme_id=' + id
				// })
			}
		}
	}
</script>

<style  scoped>
	.GI7HW_KYGFUGWvsqzBRx {
		--cardRowSpacing: 0.22rem;
		    --cardColSpacing: 0rem;
	    margin: var(--cardRowSpacing) var(--cardColSpacing);
		/* overflow: hidden !important; */
		float: left;
	}
	.game-name {
	    --advertise-img-box-core-width: calc(var(--card-benchmark));
	    --advertise-img-box-core-height: calc(var(--card-benchmark)*0.39);
	    --game-name-width: calc(var(--card-benchmark)*0.442);
	    --game-name-height: calc(var(--card-benchmark)*0.153);
	    --game-name-left: calc(var(--card-benchmark)*0.046);
	    --game-name-bottom: calc(var(--card-benchmark)*0.032);
	    --game-name-font-size: calc(var(--card-benchmark)*0.057);
	    --advertise-img-box-core-radius: 0.2rem
	}
	.T0_ZZEQjLqf2QuL3C1DZ {
	    -webkit-line-clamp: 2;
	    -webkit-box-orient: vertical;
	    bottom: var(--game-name-bottom);
	    color: #fff;
	    display: -webkit-box;
	    font-size: var(--game-name-font-size);
	    height: var(--game-name-height);
	    left: var(--game-name-left);
	    line-height: 1.25;
	    margin: var(--game-name-margin);
	    overflow: hidden;
	    position: absolute;
	    text-align: center;
	    text-overflow: ellipsis;
	    vertical-align: middle;
	    width: var(--game-name-width)
	}

	.ccXLMbc69hoUikM81AID {
	    --card-benchmark: 6.4rem
	}
	.M2jH4FHP5DfiUNlWcYJt {
	    --advertise-img-box-core-width: calc(var(--card-benchmark));
	    --advertise-img-box-core-height: calc(var(--card-benchmark)*0.39);
	    --advertise-img-box-core-radius: 0.2rem
	}
	.QY3M1Ti7e9K3q5YGzMaJ {
	    -webkit-box-shadow: var(--theme-aside-box-shadow);
	    box-shadow: var(--theme-aside-box-shadow)
	}
	.GI7HW_KYGFUGWvsqzBRx {
	    border-radius: var(--advertise-img-box-core-radius);
	    height: var(--advertise-img-box-core-height);
	    overflow: hidden;
		position: relative;
	    width: var(--advertise-img-box-core-width)
	}
	.conte_img{
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	}
	
</style>
