<template>
    <svg :class="svgClass" aria-hidden="true" >
         <use :xlink:href="iconName" />
    </svg>
  </template>
   
  <script>
  export default {
    name: "SvgIcon",
    props: {
      iconClass: {
        type: String,
        required: true,
      },
      className: {
        type: String,
        default: "",
      },
	  classNameQita: {
	    type: Boolean,
	    default: false,
	  },
	  
	  
      size: {
        type: [String, Number],
        default: 20,
      },
    },
    computed: {
      iconName() {
		  if(this.classNameQita){
			  // #icon-input_icon_fb_Facebook_f_logo__2021_.svg:all
			  return `#${this.iconClass}`
		  }
        return `#icon-sprite_${this.iconClass}`;
		// icon-input_icon_fb
		// _Facebook_f_logo__2021_.svg
		// #icon-sprite_
		// _Facebook_f_logo__2021_.svg
      },
      svgClass() {
        if (this.className) {
          return "svg-icon " + this.className;
        } else {
          return "svg-icon";
        }
      },
      swidth() {
        if (this.size < 0 || isNaN(this.size)) {
          return 20;
        }
        return this.size;
      },
      sheight() {
        if (this.size < 0 || isNaN(this.size)) {
          return 20;
        }
        return this.size;
      },
    },
  };
  </script>
   
  <style scoped>
  .svg-icon {
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    display: inline;
    width: .36rem;
    height: .36rem;
  }
  .svg-icon image{
	  vertical-align: -0.15em;
	  fill: currentColor;
	  overflow: hidden;
	  display: inline;
	 width: 100%;
	 height: 100%;
  }
  </style>