<template>
	<div class="Idioma-popup">
		<van-popup v-model="isShow" @close="closeFun">
			<div class='ant-modal-content' v-if="$attrs.shoWItemInfo.osn">
				<div class="ant-modal-header">
					
					<div class='ant-modal-title'>Detalhes do Depósito</div>
					<span @click="closeFun" class="ant-modal-close-x"><i aria-label="icon: close" class="anticon anticon-close ant-modal-close-icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" data-icon="close" fill="currentColor" aria-hidden="true" focusable="false" class=""><g id="comm_icon_x" transform="translate(-1209.5 -160.5)"><path id="Line_14" data-name="Line 14" d="M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z" transform="translate(1210.5 161.5)"></path><path id="Line_15" data-name="Line 15" d="M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z" transform="translate(1210.5 161.5)"></path></g></svg></i></span>
				</div>
				<div class='ant-modal-body'>
					<div class="mwRRRArq5gAZYhhvbvhg">
						<div class="AwOlFgTYfgvrUiDuREQ3 iSeiyC3bqps4IGGTXatc"
						:class="{
							MCxdxRhxa0voqrCEptAa1:$attrs.shoWItemInfo.status==1,
							iSeiyC3bqps4IGGTXatc1:$attrs.shoWItemInfo.status==2,
							TmTlgIIHiQR86nkpcl8M1:$attrs.shoWItemInfo.status==0,
						}"
						>
						<!-- 0 pagamento pendente, 1 pago, 10 caducou -->
							<i data-id="sprite_main_comm_icon_pay_1"
									aria-hidden="true" focusable="false" class=""
									v-if="$attrs.shoWItemInfo.status==1"
									:style="{
										'background-image':`url(${_coloImg})`
									}"
									style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -1.3rem -13.1057rem; background-size: 22.8171rem 22.2671rem;"></i>
									<i data-id="sprite_main_comm_icon_pay_1"
											aria-hidden="true" focusable="false" class=""
											v-if="$attrs.shoWItemInfo.status==0"
											:style="{
												'background-image':`url(${_coloImg})`
											}"
											style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -3.6rem -13.057rem; background-size: 22.8171rem 22.2671rem;"></i>
											
									<i data-id="sprite_main_comm_icon_pay_1"
											aria-hidden="true" focusable="false" class=""
											v-if="$attrs.shoWItemInfo.status==2"
											:style="{
												'background-image':`url(${_coloImg})`
											}"
											style="display: inline-block; position: relative; width: 1.1rem; height: 1.1rem;  background-position: -4.7rem -13.1057rem; background-size: 22.8171rem 22.2671rem;"></i>
						</div>
						<!-- display: inline-block;
						    position: relative;
						    width: 1.1rem;
						    height: 1.1rem;
						    background-image: url(blob:https://calor777.im/bd6c5a90-6e3b-45fa-a83b-256258d79c3d);
						    background-position: -4.68286rem -13.1057rem;
						    background-size: 20.9rem 20.02rem; -->
							<!-- 
							 <span class="TmTlgIIHiQR86nkpcl8M"  v-if="item.status==0">{{ $t('Login._Pagamento') }}{{ $t('Login._Pendente') }}</span>
							 <span class="TmTlgIIHiQR86nkpcl8M"  v-if="item.status==1">{{ $t('Login._Jápago') }}</span>
							 -->
							<!-- <span class="TmTlgIIHiQR86nkpcl8M"  v-if="item.status==0">{{ $t('Login._Pagamento') }}{{ $t('Login._Pendente') }}</span>
							 <span class="TmTlgIIHiQR86nkpcl8M"  v-if="item.status==1">{{ $t('Login._Jápago') }}</span>
							 <p class="ae0MZUg_2ev_myGA8A0B" v-if="item.status==10 || item.status==2"><span class="Q6NkNwL6l8CDPSdtrO2P">{{ $t('Login._PedidoExpirado') }}</span>
							 </p> -->
						<p class="W81PYwak4A0iuAdse7m7 TmTlgIIHiQR86nkpcl8M " v-if="$attrs.shoWItemInfo.status==0">{{ $t('Login._Pagamento') }} {{ $t('Login._Pendente') }}</p>
						<p class="W81PYwak4A0iuAdse7m7 MCxdxRhxa0voqrCEptAa" v-if="$attrs.shoWItemInfo.status==1">{{ $t('Login._Jápago') }}</p>
						<p class="W81PYwak4A0iuAdse7m7 Q6NkNwL6l8CDPSdtrO2P" v-if="$attrs.shoWItemInfo.status==2">{{ $t('Login._PedidoExpirado') }}</p>
						<p class="ipChavXPGtlb9XQlgfgp"><span>R$ {{$attrs.shoWItemInfo.money}}</span></p>
					</div>
					<!-- 状态:0待支付, 1:已支付，10：过期 -->
					<ul class="Tx8SM0ickmaT3XlvpBa3">
						<li><span>Tipo de transação</span><span>Depósito</span></li>
						<li><span class="">Método de Depósito</span><span class="">{{$attrs.shoWItemInfo.pay_name}}</span></li>
						<li><span class="">Canal de recarga</span><span class="">{{$attrs.shoWItemInfo.pay_name}}</span></li>
						<li><span>Hora de Criação</span><span>{{$attrs.shoWItemInfo.time}}</span></li>
						<li  @click.stop="copyContent($attrs.shoWItemInfo.osn)"><span>Número do Pedido</span><span class="s5Ow2TGiE3H2Yw1AxDNi">{{$attrs.shoWItemInfo.osn}}<span
									class="PmwtECGo9wfGBMP0uy71 copyIcon glZzWCWRIY240qdjsGDY">
									<svg-icon className="imageWrap"
										style="color: var(--theme-filter-active-color);width: .2rem;height: .2rem;margin-left: .2rem;"
										icon-class="comm_icon_copy--svgSprite:all" />
									</span></span></li>
					</ul>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	export default {
		props: {
			isShow: {
				type: Boolean,
				default: () => {
					return false;
				}
			}
		},
		data() {
			return {

			}
		},
		mounted() {
			console.log(this.$attrs)	
		},
		methods: {
			closeFun(){
				// this.isShow=false;
				this.$emit('qingkOngs')
			},
			switchLang(e = 'en') {
				this.$i18n.locale = e;
				this._i18n.locale = e;
				// this.isShow=false;
				this.$emit('_remove')
				this.$vuex('_i18nLocale', e)
			},
		}

	}
</script>

<style scoped>
	.Q6NkNwL6l8CDPSdtrO2P {
	    color: var(--cms-primary-color)
	}
	.MCxdxRhxa0voqrCEptAa {
	    color: var(--cms-global-secondary1-color)
	}
	.iSeiyC3bqps4IGGTXatc {
	    background-color: var(--theme-secondary-color-error);
	    color: var(--theme-secondary-color-error)
	}
	.TmTlgIIHiQR86nkpcl8M {
	    color: var(--theme-secondary-color-finance);
		/* background-color:var(--theme-secondary-color-finance); */
	}
	.Q6NkNwL6l8CDPSdtrO2P1 {
		background-color:var(--theme-secondary-color-error);
	}
	.MCxdxRhxa0voqrCEptAa1 {
		background-color:var(--theme-secondary-color-success);
	}
	.iSeiyC3bqps4IGGTXatc1 {
	    background-color: var(--theme-secondary-color-error);
	    color: var(--theme-secondary-color-error)
	}
	.TmTlgIIHiQR86nkpcl8M1 {
		background-color:var(--theme-secondary-color-finance);
	}
	.ant-modal-content .mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
	    font-size: .24rem;
	    height: .6rem;
	    line-height: .6rem;
	}
	.ant-modal-content {
		background-color: var(--cms-background-color-primary);
		border: thin solid;
		border-color: var(--cms-divider-color);
		border-radius: .2rem;
		max-height: calc(100vh - .1rem);
		max-height: calc(var(--vh, 1vh)*100 - .1rem);
		overflow: auto;
		position: static;
		width: 7.1rem;
		padding: 0 0 .1rem 0;
	}
	.ant-modal-close-x {
	    font-size: .2rem;
	        height: .6rem;
	        line-height: .6rem;
			    color: var(--theme-text-color);
	        width: .6rem;
			position: absolute;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			top: 0;
			font-size: .2rem;
	}
	
	.ant-modal-close:focus,.ant-modal-close:hover {
	    color: rgba(0,0,0,.75);
	    text-decoration: none
	}

	.Idioma-popup .van-popup {
		background-color: initial;
	}

	.ant-modal-content .ant-modal-header {
		border-bottom: none;
		border: none;
		border-radius: .2rem .2rem 0 0;
		padding: .2rem .3rem
	}
	.ant-modal-body::after{
		display: none;
	}
	.ant-modal-content .ant-modal-header .ant-modal-title {
		color: var(--cms-text-color-primary);
		font-size: .3rem;
		font-weight: revert;
		line-height: .34rem;
		text-align: center
	}

	.ant-modal-content .ant-modal-body {
		padding: .2rem .2rem 0 .2rem;
		position: relative;
	}

	.ant-modal-content .ant-modal-body:after {
		background-color: var(--cms-divider-color);
		content: "";
		height: .01rem;
		left: 0;
		position: absolute;
		top: 0rem;
		width: 100%
	}

	.XmFVAcOt7YCkuEJ1j2xA {
		border: thin solid var(--theme-color-line);
		border-radius: 100%;
		font-size: .18rem;
		height: .3rem;
		line-height: .33rem;
		width: .3rem
	}

	._yZsg5Aq0active .cicle {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: var(--cms-primary-color);
		border: thin solid var(--cms-primary-color);
		color: var(--theme-primary-font-color);
		display: -ms-flexbox;
		display: flex;
		justify-content: center
	}

	._yZsg5Aq0active>span {
		color: var(--cms-primary-color)
	}

	.XmFVAcOt7YCkuEJ1j2xA i {
		opacity: 0
	}

	.XmFVAcOt7YCkuEJ1j2xA .imageNav-a {
		width: .18rem;
		color: var(--cms-background-color-secondary);
		height: .18rem;
	}

	.QzRd3YqTnYxG8Latq8fW {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: none;
		border-radius: 50%;
		display: -ms-flexbox;
		display: flex;
		height: .64rem;
		justify-content: center;
		margin: 0 auto;
		margin-top: .2rem;
		width: .64rem
	}
	
	.QzRd3YqTnYxG8Latq8fW img {
		width: 100%;
		height: 100%;
	}
	.Tx8SM0ickmaT3XlvpBa3 {
	    padding: .3rem 0
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .22rem;
	    justify-content: space-between;
	    margin-bottom: .2rem;
	    min-height: .26rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X {
	    -ms-flex-align: start;
	    align-items: start;
	    border-top: thin dashed var(--theme-color-line);
	    color: var(--theme-text-color-lighten);
	    -ms-flex-direction: column;
	    flex-direction: column;
	    padding: .16rem 0 .3rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li.B1TtAa0JTkMu9BpqT74X img {
	    height: 1.25rem;
	    margin-top: .12rem;
	    width: 1.25rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li:last-child {
	    margin-bottom: 0
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li>span:first-child {
	    color: var(--theme-text-color-lighten);
	    -ms-flex: 1;
	    flex: 1;
	    max-width: 40%
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li>span:last-child {
	    color: var(--theme-text-color-darken);
	    max-width: 60%;
	    word-break: break-all
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .ImHReXiC3qHaglpWgdTK {
	    -ms-flex-item-align: start;
	    align-self: flex-start
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi {
	    -ms-flex-align: center;
	    align-items: center;
	    display: -ms-flexbox;
	    display: flex
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .s5Ow2TGiE3H2Yw1AxDNi img {
	    border-radius: .05rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .JCvkz7A6x1jjaTlhnuDX {
	    max-width: 3.3rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .PZ7EGYiPjnqzRGJ8oL6s {
	    max-width: 2.7rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA {
	    display: -ms-flexbox;
	    display: flex;
	    line-height: 1.2;
	    max-width: 2.7rem;
	    text-align: right;
	    word-break: break-all
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .DooNBP2n8v4ABSZfz_KA>span:first-child {
	    max-width: 2.3rem;
	    text-align: right
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .Md6HK8VKOeLdNOfHEVTY {
	    color: var(--theme-primary-color);
	    cursor: pointer;
	    font-size: .27rem;
	    margin-left: .1rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 li .XgqnfUsNuIo0CPXFCyLm {
	    color: var(--theme-primary-color)!important;
	    cursor: pointer
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .ns96l6JACKUvG1AFxK8m {
	    -ms-flex-align: start;
	    align-items: flex-start
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g {
	    -ms-flex-align: start;
	    align-items: start
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>span {
	    word-break: break-word!important
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g>div {
	    -ms-flex-pack: center;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: 1;
	    flex: 1;
	    justify-content: center
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 {
	    -ms-flex-align: center;
	    -ms-flex-pack: start;
	    align-items: center;
	    height: 1.5rem;
	    justify-content: flex-start
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .xMEGQ0pRRv4sF_55Er_g .TfsHLL_9svRpFp1hyDt2 .FKa0XIVD1gdhve7pK7sP {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    border: .01rem solid var(--theme-primary-color);
	    border-radius: .1rem;
	    color: var(--theme-primary-color);
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .16rem;
	    height: .5rem;
	    justify-content: center;
	    margin-left: .3rem;
	    text-align: center;
	    width: 1.2rem
	}
	
	 .Tx8SM0ickmaT3XlvpBa3 .VMmO1mBS3iM9ZHdoRrx9 {
	    font-weight: 700
	}
	 .mwRRRArq5gAZYhhvbvhg {
	    -ms-flex-align: center;
	    align-items: center;
	    border-bottom: thin dashed var(--theme-color-line);
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    padding-bottom: .3rem;
	    padding-top: .16rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .AwOlFgTYfgvrUiDuREQ3 {
	    border-radius: 50%;
	    height: 1.1rem;
	    width: 1.1rem;
	}
	
	 .mwRRRArq5gAZYhhvbvhg .W81PYwak4A0iuAdse7m7 {
	    font-size: .2rem;
	    line-height: 1.2;
	    margin: .2rem 0 .05rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    align-items: center;
	    color: var(--theme-text-color-darken);
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .4rem;
	    height: .58rem;
	    justify-content: center;
	    line-height: .58rem;
	    position: relative;
	    text-align: center;
	    width: 100%
	}
	
	 .mwRRRArq5gAZYhhvbvhg .ipChavXPGtlb9XQlgfgp>span>span {
	    font-size: .3rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS {
	    color: var(--theme-text-color-darken);
	    font-size: .22rem;
	    margin-bottom: .1rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ {
	    color: var(--theme-secondary-color-error);
	    display: inline-block;
	    font-size: .24rem;
	    margin: 0 .02rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .hG9F2N5olLZbiOF2jkhS .lYpwQ8PhWhUT4VrgkHkQ span {
	    background-image: none
	}
	
	 .mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 {
	    color: var(--theme-text-color-lighten);
	    font-size: .14rem;
	    text-align: center
	}
	
	 .mwRRRArq5gAZYhhvbvhg .CBcpFLycz4SL5QOYR0S4 span {
	    color: var(--theme-secondary-color-finance);
	    margin-left: .05rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu {
	    color: var(--theme-text-color-lighten);
	    font-size: .18rem;
	    text-align: center
	}
	
	 .mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu p {
	    line-height: .3rem
	}
	
	 .mwRRRArq5gAZYhhvbvhg .jsW62VlEjiVBmbg1goxu span {
	    color: var(--theme-secondary-color-finance);
	    margin-left: .05rem
	}
</style>
