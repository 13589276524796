<template>
	<div>
		<van-popup style="background: initial;"  v-model="$store.state.AppDownload" z-index="999999999"
			:close-on-click-overlay="false">
			<div class='ant-modal-content ' style="width: 6rem;">
				<div class="ant-modal-body">
					<div class="ant-modal-body-conter">
						<img :src="$store.state.config.home_ico" alt="">
						<span class="azs6RKGF2DadE09dhZqO">Baixe o APP：Coleção gratuita R$7,00！</span>
					</div>
					<van-button type="primary" class="rDu16GVie45q71Bb84aD">
						<div style="display: flex;align-items: center;">
							<svg-icon
								style="width: .4rem;height: .4rem;"
								icon-class="comm_icon_xz-ios--svgSprite:all" />
							<span class="_sGGPqnJR7MVJRNIl6_b">V&nbsp;{{projectName}}</span>
						</div>
					</van-button>
				</div>
			</div>
			<div class="QzRd3YqTnYxG8Latq8fW" @click="$store.commit('publicFun',['AppDownload',false])">
				<img class="closeIcon"
					src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAMAAABGS8AGAAAAilBMVEUAAAD////e5Orv8vXe5Or////e5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Ore5Or////////h5+z8/f7z9ffm6/Dp7fHt8PT5+vv2+PrCEzcDAAAAJXRSTlMAYJYggoAJR6DVzbyRfHYuimpXKBlePTURrlDbxOm27OJwZaif1OMsUAAABGNJREFUWMPdmdtiojAQhlNAOSOoeK6tugRy4P1fb0lCO26DDRiv9r8Twsfkz0wSA/ovtVh6tzj8fP/4eP+M4iBdrpC93GtwJJQ1La+leNswisOgXNtQd9czpg2vNPGG4v1y/iR2lWDaVo9Ud2xv/YyxDmnqnsG77hOCOxFhC/+6znDuTsSuHdx89ZrgU5CW/nbluqutX6ZBJP1RYeN8kiEZZv2DJMr9nfbaZRL2/alZdJ3ignyIU5xvHzXaOJipVsQZaXWBG4UNi99zJuvR7LQcw00Il63xxdw2VTG02DO3dahqmuxG1Y8jw6iJY2g4V1wWH9BIFZEMmjoGLlPv302oI4eayYqLczRJKomoY/CX4wxNVI4F+bHPDpHcAk2Wp8gPcuOChQ/AnURWzx4GByEUiYNT9JQS0ds2HjSiEb1JnoDC+LABmzMCN55SIALDpVb4wn4eWSw4G0k4aR4x8b4rslAq+ky9HyOHlRFWujUiM34ELK9JI2zM0EJewyUbJVrIHuuufNpvQsT40QKB5IVsoOVsNnMRGnEDQuYh/F5S0QV9uX2rhN7QmBuQAwTWyX33osbRw6qU3nSukh5z3Hak/J/iIBut1awC8hC3mmmPlLQfLHDiiAbBQNa51R99CcL1nReOcCIZBgNZ50LEoHPHYl9pIOZLekCDHgNZ54LHoIx1QZ7758VQalUHECBrl3RtCSTYgYh5DSET2cgFkxcQfYDMZANX6dR2vi6/x455aAR5BBcFMHpiIGmJRpDNXDXt9MUW8c4VHxnII7mooN9pEXJZdwaymQvV1qoF6hPG0UQ2c5EPOfbRgfEKmcnANYHDEWAgA9cMBivswboVRzF4W3srYPD4SUs3+8G7iqw4qwJpoUDs0y2VEwQUYfqSAgEavONVJR3L/oPd0asmIVib5Jpd491rps3F3USPjjWkhe1EX1C5sQe/m/w1S9PtfnIvhcmh9WIKFm9grwnzm93yf5AWaxsj6w0LbNagDOt3yy0W9H3z75pNC+tNob7PduS+1mYbCwGy7D6tZRcuA/vrP7C/Nt/IGVTHXfrxI7LTSgacD/zhSezADvz1+nnRt+EWIjj2M7iF6EZztuC6IRd2zjXjqTx+sTCCQQJoRVOR9GkuqWTR6fJxLWwun+OmwmAeDuamR8S96PDUwMlDIVz8ckjSxk+Qr5hrxzOa/+1pshsXzH8feddpBBlfph67cdNpx0KSOZ5SgnOH1JI7R0ZyTZ3FWK6/Z5Xk7kzntrJdg7OxNvSHsfORPavpmOwoI1rD8bFJHm7l2TTZG+akZUz4pO4dYlbLB0hUrB96loVEBlDTYDN6oBPlXMUp3hergTG+xJjxSoXrTTrpupH2+8tPeEuXW3c37964c7eltz8SJu7KTpnzRzewqZTkFyHcS34TqnqsNgwjM5So7irVUvCbMwzmTtTCw6LTuuqWkShzkYV874T774TwpZDgON0ia7mHNDgdu++anT7ej3GQ+Wv0H+ov9UMLyPmx8boAAAAASUVORK5CYII="
					alt="">
			</div>

		</van-popup>
	</div>
</template>

<script>
	import {
		changeLoginPassword
	} from "@/apis/modules/login.js";
	import {
		Toast
	} from 'vant';
	export default {
		props: {
			changePassword: {
				type: Boolean,
				default: () => {
					return false;
				}
			}
		},
		components: {},
		created() {
			let _this = this;
			this.$initGeetest4({
				captchaId: this.$store.state.config.captcha_config.id,
				product: 'bind',
				language: _this.$store.state.config.captcha_config.language
			}, function(captcha) {
				_this.captcha = captcha;
				captcha.onReady(function() {}).onSuccess(function() {
					var result = captcha.getValidate();
					result.captcha_id = _this.$store.state.config.captcha_config.id;
					_this.active = 2
					// _this.checkComplete(_this.conTnetfrom)
				}).onError(function() {

				})
				// let co=captcha.showCaptcha();
			});
		},
		methods: {
			subForm() {
				if (this.active == 1) {
					if (this.isTetUsername) {
						this.isTetUsernameValue = false;
						this.captcha.showCaptcha();
					} else {
						this.isTetUsernameValue = true;
					}
				} else if (this.active == 2) {
					changeLoginPassword({
						...this.fonData
					}).then(({
						res,
						data,
						msg
					}) => {
						Toast.fail({
							message:this.myLineBreaks(msg),
							duration:1000
						});
					})
				}

			},
			onInput(e) {
				this.isTetUsername = e;
			},
			showHide(e) {
				this.fonData[e].type = this.fonData[e].type == 'password' ? 'text' : 'password'
			},
		},
		data() {
			return {
				active: 1,
				captcha: '',
				isTetUsername: '',
				isTetUsernameValue: '',
				projectName: this.$store.state.config.project_name.split('.')[0],
				fonData: {
					new_password: '',
					new_password_confirm: '',
					new_passwordTi: {
						type: 'password',
					},
					new_password_confirmTi: {
						type: 'password',
					},
				}

			}
		}
	}
</script>
<style scoped>
	.ant-modal-content {
		background-color: var(--cms-background-color-primary);
		border: thin solid;
		border-color: var(--cms-divider-color);
		border-radius: .2rem;
		max-height: calc(100vh - .1rem);
		max-height: calc(var(--vh, 1vh)*100 - .1rem);
		overflow: auto;
		position: static
	}
	.ant-modal-body{
		padding: .3rem .2rem .2rem;
	}
	.ant-modal-body-conter {
		display: flex;
		box-sizing: border-box;
		align-items: center;
	}

	.ant-modal-body-conter img {
		width: 1.2rem;
	}

	.ant-modal-body-conter .azs6RKGF2DadE09dhZqO {
		color: var(--cms-text-color-secondary);
		font-size: .3rem;
		margin-left: .2rem;
		text-align: left
	}

	.conterList {
		height: .7rem;
		width: 100%;
		border-radius: .1rem;
	}
	html .van-button.van-button--primary{
		    color: var(--cms-top-text-color);
	}
	.rDu16GVie45q71Bb84aD{
		    margin-top: .67rem;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			border-radius: .14rem;
			 color: var(--theme-primary-color);
			 background: transparent !important;
	}
	._sGGPqnJR7MVJRNIl6_b {
	    font-size: .22rem;
	    margin-left: .08rem;
	    overflow: hidden;
	}
	.steps-conter {
		display: flex;
		align-items: center;
		padding: 0 .8rem;
		width: 100%;
		padding-bottom: .3rem;
		justify-content: space-between;
	}

	.steps-conter .step {
		background-color: var(--theme-main-bg-color);
		border: thin solid var(--theme-color-line);
		border-radius: 50%;
		font-size: .24rem;
		height: .6rem;
		line-height: .6rem;
		margin: 0;
		display: inline-block;
		width: .6rem;
		z-index: 9;
		min-width: .6rem;
		min-height: .6rem;
		text-align: center;
		max-width: .6rem;
		max-height: .6rem;
		color: var(--theme-text-color-lighten);
		font-size: .24rem;
		position: relative;
	}

	.ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		transition: color .3s cubic-bezier(.215, .61, .355, 1);
		line-height: 1.5 !important;
		position: relative;
		padding-left: .3rem;
		margin-bottom: .2rem;
	}

	.ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		left: 0;
		top: 50%;
		margin-top: -0.09rem;
		width: 0.18rem;
		display: inline-block;
		position: absolute;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}

	.steps-conter .step .unmber {
		text-align: center;
	}

	.steps-conter .active.step {
		background-color: var(--theme-primary-color);
		border: thin solid var(--theme-primary-color);
		color: var(--theme-primary-font-color) !important;
	}

	.steps-conter .active.step .finish-text {
		color: var(--theme-primary-color) !important;
	}

	.steps-conter .step .step__line {
		background-color: var(--theme-color-line);
		height: 100%;
		position: absolute;
		top: 50%;
		z-index: 0;
		left: .6rem;
		transform: translate(0, -50%);
		height: .1rem;
		width: 1.53rem;
	}

	.steps-conter .step:last-child .step__line {
		width: 0;
	}

	.steps-conter .step .finish-text {
		position: absolute;
		bottom: -.5rem;
		white-space: nowrap;
		left: 50%;
		transform: translate(-50%);
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
	}

	/* .finish-text{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
	} */
	.ant-modal-content .ant-modal-header {
		border-bottom: none;
		border-radius: .2rem .2rem 0 0;
		padding: .2rem .3rem
	}

	.ant-modal-header .ant-modal-title {
		color: var(--cms-text-color-primary);
		font-size: .24rem;
		font-weight: revert;
		line-height: .34rem;
		text-align: center
	}


	.QzRd3YqTnYxG8Latq8fW {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background: none;
		border-radius: 50%;
		bottom: -.88rem;
		display: -ms-flexbox;
		display: flex;
		height: .64rem;
		justify-content: center;
		margin-right: -.3rem;
		position: absolute;
		right: 50%;
		top: auto;
		width: .64rem
	}

	.QzRd3YqTnYxG8Latq8fW .closeIcon {
		width: 100%;
		height: 100%;
	}
</style>
